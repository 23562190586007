// @flow
import React from 'react';
import {searchFieldMappings} from "../../../helpers/helpers";
import {defaultPreregistrationFields} from "../../customizeRegistration/defaultFields";
import Autocomplete from "../../autocomplete/autocomplete";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {SearchOutlined} from "@mui/icons-material";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import useTaxisSearch from "../../../hooks/taxis/useTaxisSearch";

type Props = {

};

const TaxisSearch = (props: Props) => {
	const {
		searchFieldRef,
		onChange,
		options,
		field,
		textfieldProps,
		loading,
		handleOptions,
		onVisitorSelect,
		interests,
		postalCodeFieldRef,
		enableSearch,
		values,
		formik
	} = props
	const {t} = useTranslation(VISITOR_PROFILE)
	const {loading: taxisLoading, taxisSearch} = useTaxisSearch(formik)
	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%'
			}}
		>
			<Box width={'100%'}>
				<Autocomplete
					searchFieldRef={searchFieldRef}
					onChange={onChange}
					display={'table'}
					options={options}
					searchFieldMappings={searchFieldMappings}
					defaultPreregistrationFields={defaultPreregistrationFields}
					field={field}
					textfieldProps={textfieldProps}
					loading={loading}
					searchAfterNoOfCharacters={3}
					handleOptions={handleOptions}
					onVisitorSelect={onVisitorSelect}
					interests={interests}
					postalCodeFieldRef={postalCodeFieldRef}
					enableSearch={enableSearch}
					values={values}
				/>
			</Box>
			<LoadingButton
				size={"small"}
				startIcon={<SearchOutlined/>}
				variant="text"
				color="success"
				onClick={() => taxisSearch(searchFieldRef?.current?.value)}
				sx={{minHeight: '40px', minWidth: '160px'}}
				loading={taxisLoading}
				loadingPosition="start"
			>
				{normalizeGreek(t('TAXIS Search'))}
			</LoadingButton>
		</Box>
	);
};

export default TaxisSearch
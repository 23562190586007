// @flow
// noinspection JSUnresolvedVariable

import * as React from 'react';
import {ElementTypes} from "../customizeRegistration/customizeReregistration";
import {Grid, Stack} from "@mui/material";
import RenderFieldArray from "../renderFieldArray/renderFieldArray";
import RenderField from "../renderField/renderField";

type Props = {

};

const RenderFieldGroups = (props: Props) => {
    const {fields, formik, values, handleBlur, handleChange, touched, errors} = props
    return (
        <Grid container spacing={'6px'}>
            {fields.map(({data, depth, children, hasChildren, id}) => {
                return (
                    <React.Fragment key={id}>
                        {(data.type === 'layout_element'
                            && data.blockElementType === ElementTypes.Column) ? (
                                <Grid item xs>
                                    <RenderFieldGroups
                                        formik={formik}
                                        values={values}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        fields={children}
                                    />
                                </Grid>
                            ) :
                            (data.type === 'layout_element'
                                && data.blockElementType === ElementTypes.Row) ? (
                                <Grid item xs={12}>
                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{
                                            '& > div': {
                                                width: "100%"
                                            }
                                        }}
                                    >
                                        <RenderFieldArray
                                            formik={formik}
                                            values={values}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                            fields={children.map(c => c.data)}
                                        />
                                    </Stack>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <RenderField
                                        formik={formik}
                                        field={data}
                                        values={values}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </Grid>
                            )}
                    </React.Fragment>
                );
            })}
        </Grid>
    )
};

export default RenderFieldGroups
//@flow
import { createSelector } from 'reselect'
import { useSelector } from "react-redux";
import { parseUser } from "../../types/user/parse";

const selectIsLoggedIn = createSelector(
    (state) => state.currentUser,
    (item) => item.get('isLoggedIn')
)

const selectLoginName = createSelector(
    (state) => state.currentUser,
    (item) => item.getIn(['data', 'loginName'])
)


const selectLoading = createSelector(
    (state) => state.currentUser,
    (item) => item.get('loading')
)

const selectErrorLogin = createSelector(
    (state) => state.currentUser,
    (item) => item.get('errorLogin')
)

const selectErrorRegister = createSelector(
    (state) => state.currentUser,
    (item) => item.get('errorRegister')
)

const selectRegisteredSuccessfully = createSelector(
    (state) => state.currentUser,
    (item) => item.get('registeredSuccessfully')
)

const selectLanguage = createSelector(
    (state) => state.currentUser,
    (item) => item.get('language')
)

const selectData = createSelector(
    [
        (state) => state.currentUser.get('data'),
        (state) => state.currentUser.get('language'),
    ],
    (item, language) => parseUser(item, language)
)

const selectToken = createSelector(
    (state) => state.currentUser,
    (item) => item.get('token')
)

const useUserData = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const loginName = useSelector(selectLoginName)
    const loading = useSelector(selectLoading)
    const errorLogin = useSelector(selectErrorLogin)
    const errorRegister = useSelector(selectErrorRegister)
    const registeredSuccessfully = useSelector(selectRegisteredSuccessfully)
    const language = useSelector(selectLanguage)
    const data = useSelector(selectData)
    const token = useSelector(selectToken)
    return {
        isLoggedIn,
        loginName,
        loading,
        errorLogin,
        errorRegister,
        registeredSuccessfully,
        language,
        data,
        token
    }
}

export default useUserData
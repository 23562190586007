import * as Namespaces from './namespaces'

const locales = {
    [Namespaces.PAGE_LOGIN]: {
        el: {
            'Sign In': 'Είσοδος',
            'Your pre-registration was successful. You will receive an email and an SMS with the details for your participation.': 'Η προεγγραφή σας ήταν επιτυχής. Θα λάβετε ένα email και ένα μήνυμα με τα στοιχεία για τη συμμετοχή σας.',
            'To complete your registration type a username and a password.': 'Για να ολοκληρώσετε την εγγραφή σας, πληκτρολογήστε ένα όνομα χρήστη και έναν κωδικό πρόσβασης.',
            'Proceed with your preregistration by filling the form below.': 'Συνεχίστε με την προεγγραφή σας συμπληρώνοντας την παρακάτω φόρμα.',
            required: 'Συμπληρώστε το πεδίο',
            User: 'Χρήστης',
            Password: 'Κωδικός',
            login: 'ΣΥΝΔΕΣΗ',
            reset: 'ΕΠΑΝΑΦΟΡΑ',
            CANCEL: 'ΑΚΥΡΩΣΗ',
            loading: 'Φόρτωση',
            select_store: 'Επιλέξτε το Κατάστημά σας',
            'Forgot your password?': 'Ξεχάσατε τον κωδικό;',
            'reset_password_message': 'Συμπληρώστε το email σας και ακολουθήστε τις οδηγίες.',
            'Press here': 'Πατήστε εδώ',
            'Network Error': 'Σφάλμα δικτύου.',
            'Unauthenticated.': 'Χωρίς εξουσιοδότηση.',
            'cannot_get_addresses': 'Δεν μπορεί να γίνει λήψη των διευθύνσεων. Παρακαλούμε δοκιμάστε αργότερα.',
        },
        en: {
            reset: 'RESET',
            'reset_password_message': 'Enter your email and follow the instructions.',
            retail_message: 'If you are a retail customer you can order Coffee Island products Online by using the Coffee Island\'s company website here',
            wholesale_1: 'CUSTOMER',
            wholesale_2: 'WHOLESALE',
            retail_1: 'CUSTOMER',
            retail_2: 'RETAIL',
            company_website: 'COMPANY WEBSITE',
            customer: 'CUSTOMER',
            required: 'Required field',
            login: 'LOGIN',
            loading: 'Loading',
            select_store: 'Select your store',
            'cannot_get_addresses': 'We cannot retrieve your store information. Try again later.',
        }
    },
    [Namespaces.PAGE_HOME]: {
        el: {
            read_more: 'ΔΙΑΒΑΣΕ ΠΕΡΙΣΣΟΤΕΡA',
        },
        en: {
            read_more: 'READ MORE',
        }
    },
    [Namespaces.PAGE_SETTINGS]: {
        el: {
            'isSMS': 'SMS',
            'Template Type': 'Τύπος',
            'Email': 'Email',
            'Mobile Phone': 'Κινητό Τηλέφωνο',
            'isSent': 'Απεσταλμένο',
            'Sent Status': 'Κατάσταση Αποστολής',
            'Created At': 'Ημερομηνία Δημιουργίας',
            'Sent At': 'Ημερομηνία Αποστολής',
            'Visitor': 'Επισκέπτης',
            'Exhibitor': 'Εκθέτης',
            'Title': 'Τίτλος',
            'Body': 'Κείμενο',
            'Enter a valid email': 'Εισαγάγετε ένα έγκυρο email',
            'Cancel': 'Ακύρωση',
            'Send': 'Αποστολή',
            'Actions': 'Ενέργειες',
            'Send Multiple': 'Πολλαπλή Αποστολή',
            'Email Submitted': 'Επιτυχής Καταχώρηση',
            'Sent': 'Απεσταλμένο',
            'Not Sent': 'Μη Απεσταλμένο',
            'Error Sending': 'Αποτυχία Αποστολής',
            'Number Of Messages Sent': 'Αριθμός Αποστολών'
        },
        en: {
            'isSent': 'Sent',
            'isSMS': 'SMS',
            'Created At': 'Creation Date',
            'Sent At': 'Sent Date',
        }
    },
}

export default locales
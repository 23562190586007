import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import {requestCreator} from '../helpers/requestCreator'
import {PortalToken} from "../helpers/constants";

const userLogin = requestCreator({
    url: '/login',
    method: 'POST',
    onSuccess: Actions.userLoginSucceeded,
    onFailure: Actions.userLoginFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
        'TokenGuid': PortalToken
    }
})

const userLogout = requestCreator({
    url: '/logout',
    method: 'GET',
    onSuccess: Actions.userLogoutSucceeded,
    onFailure: Actions.userLogoutFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
        'TokenGuid': PortalToken
    }
})

const getActiveExhibitions = requestCreator({
    url: '/getActiveExhibitions',
    method: 'GET',
    onSuccess: Actions.getActiveExhibitionsSucceeded,
    onFailure: Actions.getActiveExhibitionsFailed,
})

const getExhibitionDetails = requestCreator({
    url: '/exhibitionDetails/:id',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.getExhibitionDetailsSucceeded,
    onFailure: Actions.getExhibitionDetailsFailed,
})

const fastScanBadge = requestCreator({
    url: '/exhibition/:exhibitionId/checkentry/:badgeCode',
    hasPathParameters: ['exhibitionId', 'badgeCode'],
    method: 'GET',
    onSuccess: Actions.fastScanBadgeSucceeded,
    onFailure: Actions.fastScanBadgeFailed,
})

const getCountries = requestCreator({
    url: '/country/list',
    method: 'GET',
    onSuccess: Actions.getCountriesSucceeded,
    onFailure: Actions.getCountriesFailed,
})

const getInterests = requestCreator({
    url: '/coorganization/interests/:id',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.getInterestsSucceeded,
    onFailure: Actions.getInterestsFailed,
})

const getVisitorCategories = requestCreator({
    url: '/coorganization/characterizations/:id',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.getVisitorCategoriesSucceeded,
    onFailure: Actions.getVisitorCategoriesFailed,
})

const createVisitor = requestCreator({
    url: '/visitor/store',
    method: 'POST',
    onSuccess: Actions.createVisitorSucceeded,
    onFailure: Actions.createVisitorFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const updateVisitor = requestCreator({
    url: '/visitor/update/:id',
    hasPathParameters: ['id'],
    method: 'POST',
    onSuccess: Actions.updateVisitorSucceeded,
    onFailure: Actions.updateVisitorFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const requestHandler = {
    [ActionTypes.USER_LOGIN]: userLogin,
    [ActionTypes.USER_LOGOUT]: userLogout,
    [ActionTypes.GET_ACTIVE_EXHIBITIONS]: getActiveExhibitions,
    [ActionTypes.GET_EXHIBITION_DETAILS]: getExhibitionDetails,
    [ActionTypes.FAST_SCAN_BADGE]: fastScanBadge,
    [ActionTypes.GET_COUNTRIES]: getCountries,
    [ActionTypes.GET_INTERESTS]: getInterests,
    [ActionTypes.GET_VISITOR_CATEGORIES]: getVisitorCategories,
    [ActionTypes.CREATE_VISITOR]: createVisitor,
    [ActionTypes.UPDATE_VISITOR]: updateVisitor,
}

export default requestHandler

import type {Exhibition, Visitor} from "./types";
import {parseMultiple, withLanguage} from "../../helpers/parse";
import {parseInterests} from "../interests/parse";

export const parseExhibition: Exhibition = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        erpCode: datum.get('erpCode'),
        dateFrom: datum.get('dateFrom'),
        dateTo: datum.get('dateTo'),
        daysForCatalogSubmission: datum.get('daysForCatalogSubmission'),
        typeId: datum.get('typeId'),
        categoryId: datum.get('categoryId'),
        familyId: datum.get('familyId'),
        numberOfPartnersPerExhibitor: datum.get('numberOfPartnersPerExhibitor'),
        preregistrationEnabled: datum.get('preregistrationEnabled'),
        preregistrationFormFields: datum.get('preregistrationFormFields')?.toJS(),
        lastModifiedBy: datum.get('lastModifiedBy'),
        lastModifiedAt: datum.get('lastModifiedAt'),
        isRequireRegistration: datum.get('isRequireRegistration'),
        type: datum.get('type'),
        category: parseMultiple(datum.get('category'), (i) => i),
        family: datum.get('family'),
        image: withLanguage(language)(datum.get('image')),
        title: withLanguage(language)(datum.get('title')),
        description: withLanguage(language)(datum.get('description')),
        welcomeMessage: withLanguage(language)(datum.get('welcomeMessage')),
        preregistrationFrom: datum.get('preregistrationFrom'),
        preregistrationTo: datum.get('preregistrationTo'),
        preregistrationNeedsApproval: datum.get('preregistrationNeedsApproval'),
        catalogLocked: datum.get('catalogLocked'),
        catalogEnableKioskSign: datum.get('catalogEnableKioskSign'),
        catalogueLanguage: datum.get('catalogueLanguage'),
        displayVisitorsToExhibitor: datum.get('displayVisitorsToExhibitor'),
        displayPartnersToExhibitor: datum.get('displayPartnersToExhibitor'),
        displayServiceProvidersToExhibitor: datum.get('displayServiceProvidersToExhibitor'),
        displayStaffToExhibitor: datum.get('displayStaffToExhibitor'),
        displayOrganizersToExhibitor: datum.get('displayOrganizersToExhibitor'),
        hasVisitors: datum.get('hasVisitors'),
        hasPartners: datum.get('hasPartners'),
        hasServiceProviders: datum.get('hasServiceProviders'),
        hasStaff: datum.get('hasStaff'),
        hasOrganizers: datum.get('hasOrganizers'),
        exhibitorInvitesVisitorsWithBarcode: datum.get('exhibitorInvitesVisitorsWithBarcode'),
        coorganizationId: datum.get('coorganizationId'),
        coorganizationDescription: withLanguage(language)(datum.get('coorganizationDescription')),
        visitorCategoryRequiredInImport: datum.get('visitorCategoryRequiredInImport'),
        preregistrationRedirect: datum.get('preregistrationRedirect'),
        preregistrationHasFieldGroups: datum.get('preregistrationHasFieldGroups'),
        domainPortal: datum.get('domainPortal'),
        domainPreregistration: datum.get('domainPreregistration'),
        domainRegistration: datum.get('domainRegistration'),
        disableRegistrationMessages: datum.get('disableRegistrationMessages'),
        preregistrationCountry: datum.get('preregistrationCountry'),
        preregistrationApprovePerCountry: datum.get('preregistrationApprovePerCountry'),
        catalogueProfileLength: datum.get('catalogueProfileLength'),
        hasExhibitorBrands: datum.get('hasExhibitorBrands'),
        visitorBadgeTemplateTypeId: datum.get('visitorBadgeTemplateTypeId'),
        visitorRegBadgeTemplateTypeId: datum.get('visitorRegBadgeTemplateTypeId'),
        partnerBadgeTemplateTypeId: datum.get('partnerBadgeTemplateTypeId'),
        providerBadgeTemplateTypeId: datum.get('providerBadgeTemplateTypeId'),
        staffBadgeTemplateTypeId: datum.get('staffBadgeTemplateTypeId'),
        organizerBadgeTemplateTypeId: datum.get('organizerBadgeTemplateTypeId'),
        sendFromEmail: datum.get('sendFromEmail'),
        sendFromName: datum.get('sendFromName'),
        isKioskSignEditable: datum.get('isKioskSignEditable'),
        registrationStartDays: datum.get('registrationStartDays'),
        registrationEndDays: datum.get('registrationEndDays'),
        validQrDays: datum.get('validQrDays'),
        quickPrintFooterImage: datum.get('quickPrintFooterImage')?.toJS(),
        daysForCatalogNotification: datum.get('daysForCatalogNotification'),
        // TODO::Νομίζω δεν χρησιμοποιούνται πια. Προς αφαίρεση.
        status: datum.get('status'),
        visitors: parseMultiple(datum.get('visitors'), parseVisitor),
        badgeConfig: datum.get('badgeConfig')?.toJS(),
        b2bEnabled: datum.get('b2bEnabled'),
    }
}

export const parseVisitor: Visitor = (datum, language) => {
    if (!datum) return
    return {
        Badge: datum.get('Badge'),
        Characterization: datum.get('Characterization'),
        City: datum.get('City'),
        CompanyAddress: datum.get('CompanyAddress'),
        CompanyFirm: datum.get('CompanyFirm'),
        CompanyName: datum.get('CompanyName'),
        ContactId: datum.get('ContactId'),
        CountryId: datum.get('CountryId'),
        ExhibitionId: datum.get('ExhibitionId'),
        InvitedBy: {
            id: datum.getIn(['InvitedBy', 'id']),
            name: datum.getIn(['InvitedBy', 'name']),
            totalInvitations: datum.getIn(['InvitedBy', 'totalInvitations'])
        },
        InvitationDt: datum.get('InvitationDt'),
        PersonCount: datum.get('PersonCount'),
        PostalCode: datum.get('PostalCode'),
        Status: datum.get('Status'),
        StatusId: datum.get('StatusId'),
        VatNumber: datum.get('VatNumber'),
        VisitorBusinessPhone: datum.get('VisitorBusinessPhone'),
        VisitorEmail: datum.get('VisitorEmail'),
        VisitorMobilePhone: datum.get('VisitorMobilePhone'),
        VisitorName: datum.get('VisitorName'),
        WebSite: datum.get('Website'),
        Facebook: datum.get('Facebook'),
        Instagram: datum.get('Instagram'),
        existsInExhibition: datum.get('existsInExhibition'),
        hasRevisited: datum.get('hasRevisited'),
        id: datum.get('id'),
        interests: parseMultiple(datum.get('interests'), (i) => parseInterests(i, language)),
        isApproveRegistration: datum.get('isApproveRegistration'),
        isIndividual: datum.get('isIndividual'),
        organizerApprovalRejectReason: datum.get('organizerApprovalRejectReason'),
    }
}
import * as Namespaces from './namespaces'

const locales = {
    [Namespaces.EXHIBITION]: {
        el: {
            'User data confirmation screen': 'Οθόνη επιβεβαίωσης δεδομένων χρήστη',
            'Country Code': 'Κωδικός Χώρας',
            'Scanner': 'Σάρωση Barcode',
            'Registration': 'Καταχώρηση Επισκέπτη',
            'User': 'Χρήστης',
            'Visible on registration form': 'Ορατό στη φόρμα του registration',
            'Visitor Categories': 'Κατηγορίες Επισκεπτών',
            'Add Column': 'Προσθήκη Στήλης',
            'Add Row': 'Προσθήκη Γραμμής',
            'Visitor': 'Επισκέπτης',
            'Fields': 'Πεδία',
            'Business Categories': 'Επαγγελματικές κατηγορίες',
            'Show kiosk sign': 'Προβολή μετόπης',
            'Enable B2B': 'Ενεργοποίηση B2B',
            'Menu': 'Μενού',
            'Copied': 'Αντιγράφηκε',
            'This is the public url that visitors should follow in order to complete their preregistration.': 'Αυτή είναι η διεύθυνση url που πρέπει να ακολουθήσουν οι επισκέπτες προκειμένου να ολοκληρώσουν την προεγγραφή τους.',
            'Only unauthenticated users can access this link.': 'Μόνο μη πιστοποιημένοι χρήστες μπορούν να έχουν πρόσβαση σε αυτόν τον σύνδεσμο.',
            'Copy': 'Αντιγραφή',
            'Restore': 'Επαναφορά Τελευταίας Καταχώρησης',
            'Note': 'Σημείωση',
            'Uploading': 'Μεταφόρτωση',
            'Reports': 'Αναφορές',
            'Entrance Time': 'Ώρα εισόδου',
            'Entries per Time': 'Είσοδοι ανά Χρόνο',
            'Print paper size': 'Μέγεθος χαρτιού εκτύπωσης',
            'Label': 'Ετικέτα',
            'Frequently Asked Questions': 'Συχνές Ερωτήσεις',
            'Text': 'Κείμενο',
            'Partner added successfully': 'Ο συνεργάτης προστέθηκε με επιτυχία',
            'By enabling this option organizers will have to approve/reject users, after they complete their preregistration.': 'Με την ενεργοποίηση αυτής της επιλογής, οι διοργανωτές θα πρέπει να εγκρίνουν/απορρίπτουν τους χρήστες, αφού ολοκληρώσουν την προεγγραφή τους.',
            'Preregistration Requires Approval': 'To preregistration απαιτεί έγκριση',
            'Mobile Phone': 'Κινητό Τηλέφωνο',
            'Search visitor': 'Εύρεση επισκέπτη',
            'Allow Multiple Selection': 'Να επιτρέπεται η πολλαπλή επιλογή',
            'Logout': 'Έξοδος',
            'Account settings': 'Ρυθμίσεις λογαριασμού',
            'status_1': 'Ολοκληρωμένο',
            'status_2': 'Επερχόμενο',
            'status_3': 'Σε εξέλιξη',
            'Clear': 'Καθαρισμός',
            'PAGE NOT FOUND': 'Η ΣΕΛΙΔΑ ΔΕΝ ΒΡΕΘΗΚΕ',
            'Currently there are no active exhibitions': 'Δεν βρέθηκαν ενεργές εκθέσεις',
            'Field must be checked': 'Παρακαλούμε επιλέξτε το πεδίο',
            'Select at least one': 'Επιλέξτε τουλάχιστον ένα',
            'Visitor added successfully': 'Ο επισκέπτης προστέθηκε με επιτυχία',
            'Visitor updated successfully': 'Ο επισκέπτης ενημερώθηκε με επιτυχία',
            'Interests': 'Επαγγελματική Ιδιότητα',
            'This list is visible to exhibitors/visitors when prompted to select their interests.': 'Αυτή η λίστα είναι ορατή στους εκθέτες/επισκέπτες όταν τους ζητηθεί να επιλέξουν τα ενδιαφέροντά τους.',
            'This list is visible to exhibitors/visitors when prompted to select their business categories.': 'Αυτή η λίστα είναι ορατή στους εκθέτες/επισκέπτες όταν τους ζητηθεί να επιλέξουν τις επαγγελματικές τους κατηγορίες.',
            'Add a visitor': 'Προσθήκη επισκέπτη',
            'Select Exhibition': 'Επιλέξτε Έκθεση',
            'Send badges': 'Αποστολή badge',
            'Sent {{count}} emails  successfully': 'Στάλθηκαν {{count}} email με επιτυχία',
            '{{count}} emails will be sent': '{{count}} emails θα σταλούν',
            'required': 'Υποχρεωτικό πεδίο',
            'Required': 'Υποχρεωτικό πεδίο',
            'File is required': 'Υποχρεωτικό ανέβασμα αρχείου',
            'Enter a valid email': 'Εισαγάγετε ένα έγκυρο email',
            'Enter a valid phone': 'Εισαγάγετε ένα έγκυρο τηλέφωνο',
            'Enter a valid mobile phone': 'Εισαγάγετε ένα έγκυρο κινητό τηλέφωνο',
            'Confirm': 'Επιβεβαίωση',
            'Next Step': 'Επόμενο Βήμα',
            'Previous Step': 'Προηγούμενο Βήμα',
            'Send': 'Αποστολή',
            'An email will be sent to the following address(es)': 'Θα σταλεί ένα email στις ακόλουθες διευθύνσεις',
            'send_form_step_2': 'Γράψτε την διεύθυνση email στην οποία θα αποσταλεί το συγκεντρωτικό αρχείο.',
            'Select Email': 'Επιλογή Διεύθυνσης Email',
            'Select Action': 'Επιλογή Ενέργειας',
            'Action to perform': 'Επιλογή Ενέργειας',
            'Generate consolidated file and send to one person': 'Δημιουργία ενοποιημένου αρχείου και αποστολή σε ένα άτομο',
            'Send email to everyone': 'Αποστολή email σε όλους',
            'send_form_step_1': 'Μπορείτε να επιλέξετε είτε να στείλετε ένα email ρητά σε όλους τους χρήστες που επιλέξατε νωρίτερα, είτε μπορείτε να δημιουργήσετε ένα συγκεντρωτικό αρχείο το οποίο θα σταλεί σε μια διεύθυνση email που καθορίσετε σε επόμενο βήμα. Αυτό μπορεί να είναι χρήσιμο εάν θέλετε να εκτυπώσετε/διανείμετε μόνοι σας τις προσκλήσεις ή τα badges.',
            'Partner removed successfully': 'Ο συνεργάτης αφαιρέθηκε με επιτυχία',
            'Partner already exists': 'Ο συνεργάτης υπάρχει ήδη',
            'Visitor removed successfully': 'Ο επισκέπτης αφαιρέθηκε με επιτυχία',
            'Profile updated successfully': 'Το προφίλ ενημερώθηκε με επιτυχία',
            'Exhibition updated successfully': 'Η έκθεση ενημερώθηκε με επιτυχία',
            'This action is permanent. Press OK if you want to proceed.': 'Αυτή η ενέργεια είναι μόνιμη. Πατήστε OK εάν θέλετε να συνεχίσετε.',
            'Confirm Delete': 'Επιβεβαίωση Διαγραφής',
            'Text Color': 'Χρώμα κειμένου',
            'Background Color': 'Χρώμα φόντου',
            'English Category Description': 'Αγγλική Περιγραφή Κατηγορίας',
            'Greek Category Description': 'Ελληνική Περιγραφή Κατηγορίας',
            'No categories yet': 'Δεν υπάρχουν ακόμη κατηγορίες',
            'Click the button bellow to enter visitor categories.': 'Κάντε κλικ στο κουμπί παρακάτω για να εισαγάγετε τις κατηγορίες επισκεπτών.',
            'Add': 'Προσθήκη',
            'Color': 'Χρώμα',
            'Design': 'Σχεδιασμός',
            'Categories': 'Κατηγορίες',
            'Preview': 'Προεπισκόπηση',
            'No description yet': 'Δεν έχει συμπληρωθεί η περιγραφή ακόμα',
            "Description": 'Περιγραφή',
            "Drag 'n' drop some files here, or click to select files": 'Σύρετε και αποθέστε μερικά αρχεία εδώ ή κάντε κλικ για να επιλέξετε αρχεία',
            'Uploaded File(s)': 'Ανεβασμένα Αρχεία',
            'Selected File(s)': 'Επιλεγμένα Αρχεία',
            'Download File': 'Κατέβασμα Αρχείου',
            'Download': 'Κατέβασμα',
            'Delete': 'Διαγραφή',
            'Greek Description': 'Ελληνική Περιγραφή',
            'English Description': 'Αγγλική περιγραφή',
            'NO WELCOME PAGE': 'ΔΕΝ ΥΠΑΡΧΕΙ ΣΕΛΙΔΑ ΚΑΛΩΣΟΡΙΣΜΑΤΟΣ',
            'here': 'εδώ',
            'You can edit this exhibition\'s welcome screen': 'Μπορείτε να επεξεργαστείτε την οθόνη καλωσορίσματος αυτής της έκθεσης',
            'Catalog': 'Κατάλογος',
            'Catalog Locked': 'Κλείδωμα Καταλόγου',
            'If you enable this, then exhibitors will not be able to edit their catalog information. However they will be able to view them.': 'Εάν το ενεργοποιήσετε, τότε οι εκθέτες δεν θα μπορούν να επεξεργαστούν τις πληροφορίες του καταλόγου τους. Ωστόσο, θα μπορούν να τις δουν.',
            'Number of partners per exhibitor': 'Αριθμός συνεργατών ανά εκθέτη',
            'Limit the number of partners an exhibitor can invite. Leave empty to allow infinite number of partners.': 'Περιορίστε τον αριθμό των συνεργατών που μπορεί να προσκαλέσει ένας εκθέτης. Αφήστε κενό για να επιτρέψετε άπειρο αριθμό συνεργατών.',
            'Logo': 'Λογότυπο',
            'Number of days before catalog becomes non editable': 'Αριθμός ημερών πριν ο κατάλογος γίνει μη επεξεργάσιμος',
            'Exhibitors will not be able to update their catalog profile after the number of days you specify here, counting from event start date.': 'Οι εκθέτες δεν θα μπορούν να ενημερώσουν το προφίλ του καταλόγου τους μετά τον αριθμό των ημερών που προσδιορίζετε εδώ, μετρώντας από την ημερομηνία έναρξης της εκδήλωσης.',
            'Customize': 'Σχεδιασμός',
            'Email/SMS Text Creation': 'Δημιουργία Κειμένων Email/SMS',
            'Enter details about the event and files you want to display to exhibitors.': 'Εισαγάγετε λεπτομέρειες σχετικά με την εκδήλωση και τα αρχεία που θέλετε να εμφανίσετε στους εκθέτες.',
            'Start': 'Έναρξη',
            'General': 'Γενικές',
            'End': 'Λήξη',
            'to': 'έως',
            'Enabled': 'Ενεργοποιημένο',
            'Enable preregistration': 'Ενεργοποίηση preregistration',
            'Preregistration Dates': 'Ημερομηνίες προεγγραφής',
            'Set the date range when preregistration is allowed.': 'Ορίστε το εύρος ημερομηνιών κατά το οποίο επιτρέπεται η προεγγραφή.',
            'Field Settings': 'Ρυθμίσεις πεδίων',
            'Customize preregistration form layout, enabled-disabled fields etc': 'Προσαρμόστε τη διάταξη της φόρμας προεγγραφής, τα ενεργοποιημένα-απενεργοποιημένα πεδία κλπ',
            'Visible on confirmation display': 'Εμφανίζεται στην οθόνη επιβεβαίωσης',
            'Disable this option if you want to hide this field from visitor display after a badge is scanned.': 'Απενεργοποιήστε αυτήν την επιλογή εάν θέλετε να αποκρύψετε αυτό το πεδίο από την οθόνη προβολής των στοιχείων των επισκεπτών μετά τη σάρωση ενός σήματος.',
            'Menu not configured yet': 'Το μενού δεν έχει διαμορφωθεί ακόμα',
            'Topic body for language {{language}} not configured yet.': 'Το περιεχόμενο του θέματος για τη γλώσσα {{language}} δεν έχει οριστεί ακόμα.',
            'Topic body not configured yet.': 'Το περιεχόμενο του θέματος δεν έχει οριστεί ακόμα.',
            'You can create your first topic by clicking the button below.': 'Μπορείτε να δημιουργήσετε το πρώτο σας θέμα κάνοντας κλικ στο κουμπί παρακάτω.',
            'Add topic': 'Προσθήκη Θέματος',
            'Greek': 'Ελληνικά',
            'English': 'Αγγλικά',
            'Attachments': 'Συνημμένα',
            'Status': 'Κατάσταση',
            'Title': 'Τίτλος',
            'FILTER BY': 'ΦΙΛΤΡΑ',
            read_more: 'ΕΙΣΟΔΟΣ',
            'Welcome Page': 'Σελίδα Καλωσορίσματος',
            'FAQs': 'Συχνές Ερωτήσεις',
            'Save': 'Αποθήκευση',
            'Check In': 'Check In',
            'Send SMS/Email': 'Αποστολή SMS/Email',
            'Clear Form After Submit': 'Καθαρισμός φόρμας μετά την υποβολή',
            'Update': 'Ενημέρωση',
            'Edit': 'Επεξεργασία',
            'Cancel': 'Ακύρωση',
            'Remove': 'Αφαίρεση',
            'Useful Files': 'Χρήσιμα Αρχεία',
            'Details': 'Λεπτομέρειες',
            'Exhibitors': 'Εκθέτες',
            'Visitors': 'Επισκέπτες',
            'Settings': 'Ρυθμίσεις',
            'Company profile group': 'Στοιχεία Εταιρείας',
            'Personal profile group': 'Προσωπικά Στοιχεία',
            'Country': 'Χώρα',

            'erpTotalCount': 'Σύνολο ERP',
            'erpVisitorCount': 'Επισκέπτες ERP',
            'notErpTotalCount': 'Σύνολο Εκτός ERP',
            'notErpVisitorCount': 'Σύνολο Επισκεπτών Εκτός ERP',
            'preregistrationTotalCount': 'Σύνολο Προεγγραφής ',
            'preregistrationVisitorCount': 'Σύνολο Επισκεπτών Προεγγραφής',

            'erpTotalCountCoorg': 'Σύνολο ERP Συνδιοργάνωσης',
            'erpVisitorCountCoorg': 'Επισκέπτες ERP Συνδιοργάνωσης',
            'notErpTotalCountCoorg': 'Σύνολο Εκτός ERP Συνδιοργάνωσης',
            'notErpVisitorCountCoorg': 'Σύνολο Επισκεπτών Εκτός ERP Συνδιοργάνωσης',
            'preregistrationTotalCountCoorg': 'Σύνολο Προεγγραφής Συνδιοργάνωσης',
            'preregistrationVisitorCountCoorg': 'Σύνολο Επισκεπτών Προεγγραφής Συνδιοργάνωσης',

            'Total Entries': 'Σύνολο Εισόδων',
            'EntranceCount': 'Αριθμός Συνολικών Εισόδων',
            'EntranceLobby1Count': 'Είσοδοι Lobby 1',
            'EntranceLobby2Count': 'Είσοδοι Lobby 2',
            'VisitorCount': 'Είσοδοι Επισκεπτών',
            'Entries per Date': 'Είσοδοι Ανά Ημερομηνία',
            'EntranceDate': 'Ημερομηνία',
            'Entries per Hour': 'Είσοδοι Ανά Ώρα',
            'EntranceTime': 'Ώρα',
            'Entries per Half Hour': 'Είσοδοι Ανά Μισάωρο',
            'Entries per Region': 'Είσοδοι Ανά Περιοχή',
            'Region': 'Περιοχή',
            'Entries per Country': 'Είσοδοι Ανά Χώρα',
            'VisitorPercentage': 'Ποσοστό Επισκεπτών',
            'EXPORT DATA': 'ΕΞΑΓΩΓΗ ΔΕΔΟΜΕΝΩΝ',

            'messageTemplateType': 'Τύπος Email/SMS',
            'Sent Email/SMS List And Resends': 'Λίστα Απεσταλμένων Email/SMS Και Επαναποστολές',
            'Email/SMS List': 'Λίστα Email/SMS',
            'Resends Per Date': 'Επαναποστολές Ανά Ημέρα'
        },
        en: {
            'status_1': 'Closed',
            'status_2': 'Upcoming',
            'status_3': 'Ongoing',
            required: 'Required',
            read_more: 'ENTER',
            send_form_step_1: 'You can choose to either send an email explicitly to all the users you selected earlier, or you can generate one consolidated file which will be sent to an email address you specify here. This might be useful if you want to print/distribute the invitations or badges yourself.',
            send_form_step_2: 'Enter the email address to which the consolidated file will be sent.',
            'Company profile group': 'Company Profile',
            'Personal profile group': 'Personal Profile',

            'erpTotalCount': 'ERP Total',
            'erpVisitorCount': 'ERP Visitors',
            'notErpTotalCount': 'Non ERP Total',
            'notErpVisitorCount': 'Non ERP Visitors',
            'preregistrationTotalCount': 'Preregistration Total',
            'preregistrationVisitorCount': 'Preregistration Visitor Total',

            'erpTotalCountCoorg': 'ERP Total Coorganization',
            'erpVisitorCountCoorg': 'ERP Visitors Coorganization',
            'notErpTotalCountCoorg': 'Non ERP Total Coorganization',
            'notErpVisitorCountCoorg': 'Non ERP Visitors Coorganization',
            'preregistrationTotalCountCoorg': 'Preregistration Total Coorganization',
            'preregistrationVisitorCountCoorg': 'Preregistration Visitor Total Coorganization',

            'Total Entries': 'Total Entries',
            'EntranceCount': 'Entrance Total Count',
            'EntranceLobby1Count': 'Lobby 1',
            'EntranceLobby2Count': 'Lobby 2',
            'VisitorCount': 'Visitor Count',
            'Entries per Date': 'Entries per Date',
            'EntranceDate': 'Date',
            'Entries per Hour': 'Entries per Hour',
            'EntranceTime': 'Time',
            'Entries per Half Hour': 'Entries per Half Hour',
            'Entries per Region': 'Entries per Region',
            'Region': 'Region',
            'Entries per Country': 'Entries per Country',
            'VisitorPercentage': 'Visitor Percentage',
            'Interests': 'Profession Domain',
            'messageTemplateType': 'Email/SMS Type',
            'Restore': 'Restore Last Visitor Entry',
        },
    },
    [Namespaces.NAVIGATION]: {
        el: {
            'Home': 'Αρχική',
            'Scan Badge': 'Σάρωση Κωδικού',
            'View Scan Details': 'Προβολή Πληροφοριών Σάρωσης',
            'Logout': 'Έξοδος',
            'Help Center': 'Κέντρο Εξυπηρέτησης',
        },
        en: {},
    },
    [Namespaces.HEADER_COMPONENT]: {
        el: {
            loading: "Φόρτωση",
            menu: 'ΜΕΝΟΥ',
            news: 'ΕΝΗΜΕΡΩΣΕΙΣ',
            orders: 'ΠΑΡΑΓΓΕΛΙΕΣ',
            products: 'ΠΡΟΙΟΝΤΑ',
            reports: 'ΑΝΑΦΟΡΕΣ',
            store: 'ΚΑΤΑΣΤΗΜΑ',
            logout: 'ΕΞΟΔΟΣ',
            payments_1: 'ONLINE',
            payments_2: 'ΠΛΗΡΩΜΕΣ',
        },
        en: {
            loading: "Loading",
            menu: 'MENU',
            news: 'NEWS',
            latest: 'LATEST',
            orders: 'ORDERS',
            products: 'PRODUCTS',
            reports: 'REPORTS',
            store: 'STORE',
            logout: 'LOGOUT',
            payments_1: 'ONLINE',
            payments_2: 'PAYMENTS',
        },
    },
    [Namespaces.VISITOR_PROFILE]: {
        el: {
            'Other': 'Άλλο',
            'Row': 'Γραμμή',
            'Column': 'Στήλη',
            'Disabled': 'Απενεργοποιημένο',
            'Person Number': 'Αριθμός Ατόμων',
            'Person Count': 'Αριθμός Ατόμων',
            'Is individual': 'Ιδιώτης',
            'Has Revisited': 'Έχω ξανά επισκεφθεί την έκθεση',
            'Characterization': 'Χαρακτηρισμός',
            'Visitors': 'Επισκέπτες',
            'VAT Number': 'ΑΦΜ',
            'Visitor Count': 'Αριθμός επισκεπτών',
            'return': 'Επιστροφή',
            'preregistration_upcoming_warning': 'Η προεγγραφή θα ανοίξει στις <bold>{{date}}</bold>.',
            'preregistration_submit_warning': 'Αυτή η φόρμα θα παραμείνει δημόσια μέχρι και την <bold>{{date}}</bold>.',
            'Info': 'Σημείωση',
            'Proceed with your preregistration by filling the form below.': 'Συνεχίστε με την προεγγραφή σας συμπληρώνοντας την παρακάτω φόρμα.',
            'Preregistration is disabled for this exhibition.': 'Η προεγγραφή είναι απενεργοποιημένη για αυτήν την έκθεση.',
            'Save': 'Αποθήκευση',
            status_1: 'Καταχωρήθηκε',
            status_2: 'Αποστολή Πρόσκλησης',
            status_3: 'Αποστολή Πρόσκλησης - Διαβάστηκε',
            status_4: 'Preregistration - Εκκρεμεί Έγκριση',
            status_5: 'Αποστολή Badge',
            status_6: 'Αποστολή Badge - Λήφθηκε',
            status_7: 'Preregistration - Εγκρίθηκε',
            status_8: 'Preregistration - Απορρίφθηκε',
            Loading: "Φόρτωση",
            'Preregistration Information': "Πληροφορίες Προεγγραφής",
            'APPROVE': 'ΕΓΚΡΙΣΗ',
            'APPROVED': 'ΕΓΓΕΚΡΙΜΕΝΟΣ',
            'REJECT': 'ΑΠΟΡΡΙΨΗ',
            'REJECTED': 'ΑΠΟΡΡΙΦΘΗΚΕ',
            'PENDING': 'ΕΚΡΕΜΕΙ ΕΓΚΡΙΣΗ',
            'EVENT INFORMATION': 'ΠΛΗΡΟΦΟΡΙΕΣ ΕΚΘΕΣΗΣ',
            'Comment': 'Σχόλιο',
            'Start Date': 'Ημερομηνία Έναρξης',
            'End Date': 'Ημερομηνία Λήξης',
            'Print': 'Εκτύπωση',
            'Mail': 'Email',
            'Name': 'Ονοματεπώνυμο',
            'Invitations': 'Προσκλήσεις',
            'Surname': 'Επώνυμο',
            'Postal Code': 'Τ.Κ.',
            'Company Name': 'Επωνυμία Εταιρείας / Οργανισμός',
            'Company Firm': 'Φίρμα Εταιρείας',
            'Company Address': 'Διεύθυνση Εταιρείας',
            'Company Website': 'Ηλεκτρονική Σελίδα Εταιρείας',
            'Category': 'Κατηγορία',
            'Interests': 'Επαγγελματική Ιδιότητα',
            'City': 'Πόλη',
            'Phone': 'Τηλέφωνο',
            'Mobile Phone': 'Κινητό Τηλέφωνο',
            'Upload File': 'Ανέβασμα Αρχείου',
            'I agree to terms': 'Συμφωνώ με τους όρους',
            'Register': 'Εγγραφή',
            'Position': 'Θέση / Τμήμα',
            'Countries': 'Χώρα',
            'YES': 'ΝΑΙ',
            'NO': 'ΟΧΙ',
            'TAXIS Search': 'Αναζήτηση TAXIS',
            'Tax Reg. No. not found on TAXIS!': 'To ΑΦΜ δεν βρέθηκε στο TAXIS!',
            'Insert exactly 9 characters to search registration number on TAXIS!': 'Εισάγετε ακριβώς 9 ψηφία, για να πραγματοποιηθεί αναζήτηση στο TAXIS!',
            'Failed to search Tax Reg. No. on TAXIS!': 'Η αναζήτηση του ΑΦΜ στο TAXIS απέτυχε!'
        },
        en: {
            'Characterization': 'Category',
            status_1: 'Imported',
            status_2: 'Send Invitation',
            status_3: 'Send Invitation - Read',
            status_4: 'Preregistration - Pending Approval',
            status_5: 'Send Badge',
            status_6: 'Send Badge - Received',
            status_7: 'Preregistration - Approved',
            status_8: 'Preregistration - Rejected',
            'Has Revisited': 'I have visited this exhibition more than once',
            'VAT Number': 'Tax Registration Number',
            'Postal Code': 'Postal Code',
            'Company Name': 'Company Name / Organization',
            'Company Firm': 'Company Firm',
            'Company Address': 'Company Address',
            'Company Website': 'Company Website',
            'Category': 'Category',
            'Interests': 'Profession Domain',
            'City': 'City',
            'Phone': 'Phone',
            'Mobile Phone': 'Mobile Phone',
            'Upload File': 'Upload File',
            'I agree to terms': 'I agree to terms',
            'Register': 'Register',
            'Is individual': 'Individual',
            'Position': 'Position / Department',
            'Name': 'Name / Surname',
            'Countries': 'Country',
        },
    },
    [Namespaces.EXHIBITOR_PROFILE]: {
        el: {
            'Clear': 'Καθαρισμός',
            'Attention! Cursor must be placed inside the field to scan the barcode successfully...': 'ΠΡΟΣΟΧΗ: Ο κέρσορας πρέπει να είναι τοποθετημένος μέσα στο πεδίο για να πραγματοποιηθεί η σάρωση badge!',
            'Entry Cards (Badges)': 'Κάρτες Εισόδου (Badges)',
            'Business Categories': 'Επαγγελματικές κατηγορίες',
            'Invitation': 'Ηλεκτρονική Πρόσκληση',
            'Click this button to open a new browser tab and drag the window into another monitor': 'Κάντε κλικ σε αυτό το κουμπί για να ανοίξετε μια νέα καρτέλα του προγράμματος περιήγησης και σύρετε το παράθυρο σε μια άλλη οθόνη',
            'Code not valid. Visitor not found in current exhibition.': 'Κωδικός μη έγκυρος. Ο επισκέπτης δεν βρέθηκε σε αυτήν την έκθεση.',
            'Visitor badge already scanned.': 'Ο κωδικός έχει ήδη σαρωθεί.',
            'Visitor not approved for entry.': 'Ο επισκέπτης δεν έχει εγκριθεί.',
            'None': 'Κανένας',
            'Mobile Phone': 'Κινητό Τηλέφωνο',
            'Type': 'Τύπος',
            'more': 'ΑΤΟΜΑ',
            'required': 'Υποχρεωτικό πεδίο',
            'Required': 'Υποχρεωτικό πεδίο',
            partner_limit: "Μπορείτε να προσθέσετε <bold>έως και {{maximum}}</bold> συνεργάτες. <bold>Απομένουν {{remainder}}</bold>",
            'Send invitations': 'Αποστολή προσκλήσεων',
            'Actions': 'Ενέργειες',
            'This action is permanent. Press OK if you want to proceed.': 'Αυτή η ενέργεια είναι μόνιμη. Πατήστε OK εάν θέλετε να συνεχίσετε.',
            'Confirm Delete': 'Επιβεβαίωση Διαγραφής',
            'Remove': 'Αφαίρεση',
            'Send badge to {{count}} partners': 'Αποστολή badge σε {{count}} συνεργάτες',
            'Send invitation to {{count}} visitors': 'Αποστολή προσκλήσεων σε {{count}} επισκέπτες',
            'This list is visible to exhibitors/visitors when prompted to select their interests.': 'Αυτή η λίστα είναι ορατή στους εκθέτες/επισκέπτες όταν τους ζητηθεί να επιλέξουν τα ενδιαφέροντά τους.',
            'Send Invitation (New)': 'Αποστολή Προσκλήσεων (Νέοι)',
            'Send Invitation (All)': 'Αποστολή Προσκλήσεων (Όλες)',
            'Files': 'Αρχεία',
            'Upload any files necessary for your participation below.': 'Ανεβάστε όλα τα απαραίτητα αρχεία για τη συμμετοχή σας παρακάτω.',
            'FAQs': 'Συχνές Ερωτήσεις',
            // 'catalog_submit_disabled': 'Η επεξεργασία καταλόγου είναι απενεργοποιημένη για αυτήν την έκθεση.<br/>Μπορείτε να δείτε μόνο τις πληροφορίες του καταλόγου σας. Επικοινωνήστε μαζί μας στο <a href=\'mailto:{{email}}\'>{{email}}</a> εάν χρειάζεστε βοήθεια.',
            'catalog_submit_disabled': 'Η επεξεργασία καταλόγου είναι απενεργοποιημένη για αυτήν την έκθεση. Μπορείτε να δείτε μόνο τις πληροφορίες του καταλόγου σας. <br/> <strong>ΠΡΟΣΟΧΗ:</strong> Αλλαγές στα στοιχεία του καταλόγου δύναται να πραγματοποιηθούν μετά από επικοινωνία μαζί μας, μέχρι τις <strong>{{date}}</strong>. <br/>Επικοινωνήστε μαζί μας στο <a href=\'mailto:{{email}}\'>{{email}}</a>, εάν χρειάζεστε βοήθεια.',
            'catalog_submit_ended_warning': 'Η περίοδος κατά την οποία μπορείτε να επεξεργαστείτε τις πληροφορίες του καταλόγου σας έχει λήξει.',
            'catalog_submit_warning': 'Μπορείτε να υποβάλετε τα στοιχεία του καταλόγου σας μέχρι και την <bold>{{date}}</bold>.',
            'Info': 'Σημείωση',
            'Warning': 'Προειδοποίηση',
            'DISABLED': 'ΑΠΕΝΕΡΓΟΠΟΙΗΜΕΝΟ',
            'Misc Information': 'Λοιπές Πληροφορίες',
            'WAITING': 'ΣΕ ΑΝΑΜΟΝΗ',
            'Waiting...': 'Σε αναμονή...',
            'SCANNING': 'ΣΑΡΩΣΗ',
            'Navigate to': 'Πλοηγηθείτε στην',
            'page, from a different tab': 'σελίδα, από διαφορετική καρτέλα',
            '- AND -': '- ΚΑΙ -',
            'Scan Badge': 'Σάρωση badge',
            'Use your device to scan a QR code.': 'Χρησιμοποιήστε την συσκευή σας για να σαρώσετε έναν κωδικό QR',
            'Scan another badge': 'Σάρωση άλλου badge',
            'Save': 'Αποθήκευση',
            'Interests': 'Επαγγελματική Ιδιότητα',
            'Corridor/Number': 'Διάδρομος/Αριθμός',
            'Code': 'Κωδικός',
            'Exhibition Details': 'Λεπτομέρειες Έκθεσης',
            'Profile': 'Προφίλ',
            'Kiosk Number': 'Αριθμός Περιπτέρου',
            'Kiosk Sign': 'Μετόπη',
            'Room': 'Αίθουσα',
            'Address Category': 'Κατηγορία Διεύθυνσης',
            'Phone': 'Τηλέφωνο',
            'Invited By': 'Προσκλήθηκε Από',
            'Invitation Date': 'Ημερομηνία Πρόσκλησης',
            'Add a partner': 'Προσθήκη συνεργάτη',
            'Add a visitor': 'Προσθήκη επισκέπτη',
            'Add new visitor': 'Προσθήκη νέου επισκέπτη',
            'Search visitor': 'Εύρεση επισκέπτη',
            'Switch Camera': 'Εναλλαγή κάμερας',
            'Import from excel': 'Εισαγωγή από excel',
            'import_excel_msg': 'Εάν θέλετε να προσθέσετε περισσότερα από ένα άτομα γρήγορα, μπορείτε να ανεβάσετε ένα αρχείο <strong>Excel</strong>.<br/><ul><li>Το αρχείο excel πρέπει να ακολουθεί τη δομή που περιγράφεται παρακάτω.</li><li>Βεβαιωθείτε ότι οι στήλες σας είναι σε σειρά όπως στον πίνακα του παραδείγματος.</li><li>Για τη διευκόλυνσή σας, μπορείτε επίσης να κάνετε λήψη του κενού προτύπου excel.</li></ul>',
            'Mail': 'Email',
            'Name': 'Ονοματεπώνυμο',
            'Status': 'Κατάσταση',
            'optional': 'προαιρετικό',
            'Download excel template': 'Κατέβασμα προτύπου excel',
            'Import selected file': 'Εισαγωγή επιλεγμένου αρχείου',
            'Characterization': 'Χαρακτηρισμός',
            'Cancel': 'Ακύρωση',
            'Partners': 'Συνεργάτες',
            'Visitors': 'Επισκέπτες',
            'Catalog': 'Κατάλογος Έκθεσης',
            'Profile Information': 'Πληροφορίες Προφίλ',
            'EXPORT CATALOG DATA': 'ΕΞΑΓΩΓΗ ΣΤΟΙΧΕΙΩΝ ΚΑΤΑΛΟΓΟΥ',
            'Type contact information of the person you want to add as a visitor for this event.': 'Πληκτρολογήστε τα στοιχεία επικοινωνίας του ατόμου που θέλετε να προσθέσετε ως επισκέπτη για αυτήν την εκδήλωση.',
            'Type contact information of the person you want to add as a partner for this event.': 'Πληκτρολογήστε τα στοιχεία επικοινωνίας του ατόμου που θέλετε να προσθέσετε ως συνεργάτη για αυτήν την εκδήλωση.',
            '{{count}} emails will be sent in the next 2 hours': '{{count}} emails θα σταλούν μέσα στις επόμενες 2 ώρες',
            'emailCatalogue': 'Email Καταλόγου',
        },
        en: {
            'Required': 'Υποχρεωτικό πεδίο',
            required: 'Required',
            'emailCatalogue': 'Catalogue Email',
            'Interests': 'Profession Domain',
        },
    },
    [Namespaces.TABLE_COMPONENT]: {
        el: {
            Show: "Εμφάνιση",
            Showing: "Εμφάνιση",
            results: 'αποτελέσματα',
            Loading: "Φόρτωση",
            'per page': "ανα σελίδα",
            'Go to page': 'Πήγαινε στην σελίδα',
            'Page': 'Σελίδα',
            'of': 'από',
            'no_results': 'Δεν υπάρχουν αποτελέσματα'
        },
        en: {
            'no_results': 'No Results'
        }
    },
    [Namespaces.DELETE_CONFIRMATION]: {
        el: {
            delete: 'ΔΙΑΓΡΑΦΗ',
            cancel: 'ΑΚΥΡΟ',
        },
        en: {
            delete: 'DELETE',
            cancel: 'CANCEL',
        },
    },
    [Namespaces.SEARCH_COMPONENT]: {
        el: {
            search: "Αναζήτηση Κωδικού Προϊόντος",
            search_local: "Αναζήτηση προϊόντων στη {{orderType}}",
            results_1: 'ΑΠΟΤΕΛΕΣΜΑΤΑ',
            results_2: 'ΑΝΑΖΗΤΗΣΗΣ',
            results: 'ΑΠΟΤΕΛΕΣΜΑΤΑ',
        },
        en: {
            search: "Search Product Code",
            search_local: "Search products in {{orderType}}",
            results_1: 'SEARCH',
            results_2: 'RESULTS',
            results: 'RESULTS',
        }
    },
    [Namespaces.AXIOS_ERROR_MESSAGE]: {
        el: {
            'More': 'Περισσότερα..',
            'Less': 'Λιγότερα',
            'Unauthorized': 'Ο χρήστης δεν έχει πρόσβαση στην ιστοσελίδα.',
            'Failed to get notifications.': 'Αδυναμία φόρτωσης ειδοποιήσεων.',
            'Duplicate Entry': 'Υπάρχει ήδη αυτή η εγγραφή',
            'Not Found': 'Δεν βρέθηκε',
            errorMessage: 'Μήνυμα',
            'Message': 'Μήνυμα',
            moreMessage: '',
            'Code': 'Κωδικός',
            'Status': 'Status',
        },
        en: {
            'More': 'More..',
        }
    },
}

export default locales

import {UnmappedActiveExhibitions} from "./types";
import {withLanguage} from "../../helpers/parse";

export const parseActiveExhibitions: UnmappedActiveExhibitions = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        isCoorganization: datum.get('isCoorganization'),
        title: withLanguage(language)(datum.get('title')),
        coorganizationIds: datum.get('coorganizationIds')
    }
}
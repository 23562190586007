// @flow
import React from 'react';
import {TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import {showOption} from "../../../helpers/helpers";
import Box from "@mui/material/Box";

type Props = {};

const CountryPhoneCodeSelect = (props: Props) => {
    const {
        defaultPhoneCode,
        phoneCodes,
        searchFieldRef,
        formik,
        values,
        field,
        handleBlur,
        handleChange,
        touched,
        errors,
        disabled,
        textfieldProps,
        value,
        interests,
        postalCodeFieldRef,
        loading,
        options,
        fetchVisitors,
        onVisitorSelect,
        handleOptions,
        enableSearch,
        onPhoneCodeChange
    } = props

    const {language} = useUserData()
    const {t} = useTranslation(EXHIBITION)
    return (
        <div>
            <Autocomplete
                size="small"
                options={phoneCodes}
                isOptionEqualToValue={(opt, val) => {return opt.id === val.id || !val}}
                getOptionLabel={option => option?.description}
                value={values.countryPhoneCode}
                onChange={(event, newValue) => onPhoneCodeChange(event, newValue)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={t('Country Code')}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: "background.paper"
                            }
                        }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'chrome-off'
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: values.countryPhoneCode && values.countryPhoneCode?.image && (
                                <img style={{width: '30px', paddingRight: '6px'}} src={values.countryPhoneCode?.image}
                                     alt={values.countryPhoneCode?.code}/>
                            )
                        }}
                    />
                }
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`${option.image} 2x`}
                            src={option.image}
                            alt=""
                        />
                        {showOption(language)(option)}
                    </Box>
                )}
            />
        </div>
    );
};

export default CountryPhoneCodeSelect
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";
import React, {useEffect, useRef, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import RadioGroup from "@mui/material/RadioGroup";
import type {Exhibition} from "../../types/exhibition/types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {normalizeGreek} from "../../helpers/normalizeGreek";
import Typography from "@mui/material/Typography";
import ContentLoader from "../contentLoader/contentLoader";

const SelectExhibitionDialog = (props) => {
    const {t} = useTranslation(EXHIBITION)
    const {loading, options, onSubmit, onClose, value: valueProp, open, ...other} = props
    const [value, setValue] = useState('')
    const radioGroupRef = useRef(null)
    useEffect(() => {
        if (open && options && options.length === 1) {
            onSubmit(options[0].id)
        }
    }, [onSubmit, open, options]);
    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus()
        }
    }
    const handleCancel = () => onClose()
    const handleChange = (event) => setValue(event.target.value)
    const handleSubmit = () => {
        return onSubmit(value)
    }
    return (
        <Dialog
            sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
            maxWidth="xs"
            TransitionProps={{onEntering: handleEntering}}
            open={open}
            {...other}
        >
            <DialogTitle>{t('Select Exhibition')}</DialogTitle>
            <DialogContent dividers>
                {options && options.length > 0 ? (
                    <RadioGroup
                        ref={radioGroupRef}
                        aria-label="ringtone"
                        name="ringtone"
                        value={value}
                        onChange={handleChange}
                    >
                        {options.map((option: Exhibition) => (
                            <FormControlLabel
                                value={option.id}
                                key={option.id}
                                control={<Radio/>}
                                label={option.title}
                            />
                        ))}
                    </RadioGroup>
                ) : loading ? (
                    <ContentLoader
                        loading={loading}
                    />
                ) : (
                    <Typography variant={"body1"}>
                        {t('Currently there are no active exhibitions')}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                {options && options.length > 0 ? (
                    <React.Fragment>
                        <Button
                            size={"small"}
                            color={"primary"}
                            variant={"contained"}
                            onClick={handleSubmit}>
                            OK
                        </Button>
                        {onClose && (
                            <Button
                                size={"small"}
                                onClick={handleCancel}
                                color={"error"}
                            >
                                {normalizeGreek(t('Cancel'))}
                            </Button>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {onClose && (
                            <Button
                                size={"small"}
                                color={"primary"}
                                variant={"contained"}
                                onClick={handleCancel}>
                                OK
                            </Button>
                        )}
                    </React.Fragment>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default SelectExhibitionDialog
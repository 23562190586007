// @flow
import React, {useState} from 'react';
import type {Exhibition} from "../../types/exhibition/types";
import type {Interests} from "../../types/interests/type";
import type {Countries} from "../../types/countries/type";
import {FormikProvider} from "formik";
import Box from "@mui/material/Box";
import Fields from "../fields/fields";
import FormToolbar from "../formToolbar/formToolbar";
import {useForm} from "../../hooks/registration/useForm";
import InfoPopOver from "../infoPopOver/infoPopOver";
import RegistrationInfo from "../registrationInfo/registrationInfo";

type Props = {
    id: string,
    exhibition: Exhibition,
    interests: Interests,
    countries: Countries
};

export const RegistrationContext = React.createContext(null)

const Form = ({
                  id,
                  exhibition,
                  countries,
                  interests,
                  categories,
                  phoneCodes,
                  defaultCountry,
                  defaultPhoneCode
              }: Props) => {
    const {
        loading,
        companyNameFieldRef,
        nameFieldRef,
        vatFieldRef,
        emailFieldRef,
        mobilePhoneFieldRef,
        phoneFieldRef,
        companyAddressFieldRef,
        cityFieldRef,
        companyWebsiteFieldRef,
        facebookFieldRef,
        instagramFieldRef,
        positionFieldRef,
        personNumberFieldRef,
        postalCodeFieldRef,
        otherInterestsFieldRef,
        formik,
        fields,
        fieldValues,
        submitForm,
        resetForm,
        clearFormAfterSubmit,
        handleClearFormAfterSubmit,
        onVisitorSelect,
        onFetchLastVisitor,
        sendMessages,
        handleSendMessages,
        existingVisitorToNewEntry,
        phoneCode,
        onPhoneCodeChange
    } = useForm({id, exhibition, countries, interests, phoneCodes, defaultCountry, defaultPhoneCode, categories})

    const [infoPopOver, setInfoPopOver] = useState({
        anchorEl: null,
        open: false,
        body: ''
    })

    const handleInfoPopOver = (value) => {
        setInfoPopOver({...value})
    }

    return (
        <RegistrationContext.Provider
            value={{
                id: id,
                exhibition: exhibition,
                searchOnlyAmongExhibitionVisitors: false,
                interests: interests,
                categories: categories,
                countries: countries,
                phoneCodes: phoneCodes,
                companyNameFieldRef: companyNameFieldRef,
                nameFieldRef: nameFieldRef,
                vatFieldRef: vatFieldRef,
                emailFieldRef: emailFieldRef,
                mobilePhoneFieldRef: mobilePhoneFieldRef,
                phoneFieldRef: phoneFieldRef,
                companyAddressFieldRef: companyAddressFieldRef,
                cityFieldRef: cityFieldRef,
                companyWebsiteFieldRef: companyWebsiteFieldRef,
                facebookFieldRef: facebookFieldRef,
                instagramFieldRef: instagramFieldRef,
                positionFieldRef: positionFieldRef,
                personNumberFieldRef: personNumberFieldRef,
                postalCodeFieldRef: postalCodeFieldRef,
                otherInterestsFieldRef: otherInterestsFieldRef,
                defaultCountry: defaultCountry,
                defaultPhoneCode: defaultPhoneCode,
                phoneCode: phoneCode,
                enableSearchableFields: true,
                searchFieldsOnly: false,
                limitedView: false,
                onVisitorSelect: onVisitorSelect,
                onPhoneCodeChange: onPhoneCodeChange,

            }}
        >
            <FormikProvider value={formik}>
                <Box
                    id={"preregistration"}
                    component="form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <Fields
                        formik={formik}
                        fields={fields}
                        values={fieldValues}
                        handleBlur={formik.handleBlur}
                        handleChange={formik.handleChange}
                        touched={formik.touched}
                        errors={formik.errors}
                    />
                </Box>
                <FormToolbar
                    clearFormAfterSubmit={clearFormAfterSubmit}
                    handleClearFormAfterSubmit={handleClearFormAfterSubmit}
                    sendMessages={sendMessages}
                    handleSendMessages={handleSendMessages}
                    formik={formik}
                    resetForm={resetForm}
                    submitForm={submitForm}
                    loading={loading}
                    onFetchLastVisitor={onFetchLastVisitor}
                    existingVisitorToNewEntry={existingVisitorToNewEntry}
                    handleInfoPopOver={handleInfoPopOver}
                    disableRegistrationMessages={exhibition?.disableRegistrationMessages}
                />
            </FormikProvider>
            <InfoPopOver
                infoPopOver={infoPopOver}
                handleInfoPopOver={handleInfoPopOver}
                component={<RegistrationInfo />}
            />
        </RegistrationContext.Provider>
    );
};

export default Form
export const registrationInfo = [
    "<strong>DEFEA:</strong> ΕΠΙΤΡΕΠΟΝΤΑΙ ΜΟΝΟ ΛΑΤΙΝΙΚΟΙ ΧΑΡΑΚΤΗΡΕΣ ΣΤΑ ΠΕΔΙΑ ΟΝΟΜΑΤΕΠΩΝΥΜΟ, ΕΠΩΝΥΜΙΑ ΕΤΑΙΡΕΙΑΣ, ΘΕΣΗ/ΤΜΗΜΑ, ΔΙΕΥΘΥΝΣΗ ΕΤΑΙΡΕΙΑΣ",
    "<strong>Πεδία Αναζήτησης:</strong> Επωνυμία Εταιρείας, Ονοματεπώνυμο, ΑΦΜ, Email, Τηλέφωνο.",
    "<strong>Επιλογή αποτελέσματος αναζήτησης:</strong><br/>Μετά την επιλογή αποτελέσματος από ένα πεδίο αναζήτησης, τα υπόλοιπα πεδία δεν ξανά κάνουν αναζήτηση όταν γράψουμε κάτι σε αυτά. <br/>Σε περίπτωση λάθους πατάμε ΑΝΤΙΓΡΑΦΗ ή ΚΑΘΑΡΙΣΜΟΣ για να ξανά μπορέσει να γίνει αναζήτηση στη φόρμα.",
    "<strong>Pop Up Αναζήτησης:</strong><br/>Το pop up κλείνει αν μεταβούμε σε άλλο πεδίο, αν πατήσουμε στο πληκτρολόγιο <strong>ESC</strong> ή αν κάνουμε click με το ποντίκι σε άλλο σημείο της φόρμας.<br/>Σε περίπτωση που κάναμε αναζήτηση και δεν επιλέξαμε ένα από τα αποτελέσματα, αν ξανά πατήσουμε το πεδίο αναζήτησης ξανά ανοίγει το pop up με τα τελευταία αποτελέσματα.",
    "<strong>Πεδίο Κωδικός Χώρας Και Κινητό:</strong><br/> Το κινητό πρέπει να ξεκινάει με τον τηλεφωνικό κωδικό της χώρας του επισκέπτη.<br/> Το πεδίο Κωδικός Χώρας είναι καθαρά βοηθητικό. Μας δίνει τη δυνατότητα να ψάξουμε τους κωδικούς των χωρών. <br/> Όταν κάνουμε μία επιλογή κωδικού <strong>απλώς βάζει τον κωδικό στο πεδίο του κινητού<strong>.",
    "<strong>Επαγγελματική Ιδιότητα:</strong> Σε περίπτωση που η ιδιότητα του επισκέπτη δε βρίσκεται στην υπάρχουσα λίστα, μπορούμε να συμπληρώσουμε την ιδιότητά του στο πεδίο 'Άλλο'.",
    "<strong>CHECK IN:</strong> <br/>Κάνει είτε νέα καταχώρηση είτε ενημέρωση των στοιχείων επισκέπτη που έχει επιλεχτεί από πεδίο αναζήτησης. <br/>Μετά το Check In η φόρμα καθαρίζει από ότι στοιχείο έχουμε συμπληρώσει, ανοίγει η εκτύπωση του badge του επισκέπτη που καταχωρήθηκε και γίνεται αποστολή email και SMS με το barcode σε αυτόν.",
    "<strong>ΑΝΤΙΓΡΑΦΗ:</strong> Σε περίπτωση που έχουμε επιλέξει επισκέπτη από πεδίο αναζήτησης, αλλά θέλουμε να τον καταχωρήσουμε σαν νέα εγγραφή, πατάμε πρώτα ΑΝΤΙΓΡΑΦΗ και μετά CHECK IN",
    "<strong>ΕΠΑΝΑΦΟΡΑ ΤΕΛΕΥΤΑΙΑΣ ΚΑΤΑΧΩΡΗΣΗΣ:</strong> Χρήση στη περίπτωση που θέλουμε να επαναφέρουμε τα στοιχεία του τελευταίου επισκέπτη που καταχωρήσαμε.",
    "<strong>ΚΑΘΑΡΙΣΜΟΣ:</strong> Σε περίπτωση που θέλουμε να καθαρίσουμε τη φόρμα από ότι έχουμε συμπληρώσει.",
    "<strong>Πλαίσιο 'Καθαρισμός φόρμας μετά την υποβολή':</strong> Σε περίπτωση που μετά την αποθήκευση δε θέλουμε να καθαρίσει η φόρμα από τα στοιχεία που γράψαμε αποεπιλέγουμε το συγκεκριμένο πλαίσιο.",
    "<strong>Πλαίσιο 'Αποστολή SMS/Email':</strong> Σε περίπτωση που μετά την αποθήκευση δε θέλουμε να σταλεί Email ή SMS στον επισκέπτη που καταχωρήσαμε αποεπιλέγουμε το συγκεκριμένο πλαίσιο. Σε μερικές εκθέσεις η δυνατότητα αυτή είναι απενεργοποιημένη από τον οργανωτή της έκθεσης.",
    "<strong>Πεδία Με Αστερίσκο':</strong> Τα πεδία με αστερίσκο είναι υποχρεωτικά.",
    "<strong>Μετάβαση Σε Άλλο Πεδίο Με Πληκτρολόγιο:</strong> Μπορούμε να πάμε στο επόμενο πεδίο πατώντας <strong>TAB</strong> ή στο προηγούμενο πεδίο πατώντας <strong>SHIFT + TAB</strong>",
    "<strong>Ταχυδρομικός Κώδικας:</strong> Αν γράψουμε Τ.Κ. συμπληρώνεται αυτομάτως η πόλη, αν υπάρχει αντιστοιχία.",
]

export const scanInfo = [
    "<strong>Σάρωση με Scanner:</strong> Για να πραγματοποιηθεί σάρωση με scanner, πρέπει ο κέρσορας να είναι τοποθετημένος μέσα στο πεδίο. Μετά από μία σάρωση, το πεδίο καθαρίζει αυτομάτως για να πραγματοποιηθεί η επόμενη.",
    "<strong>Σάρωση με Κάμερα:</strong> Για να πραγματοποιηθεί σάρωση με κάμερα, δε χρειάζεται ο κέρσορας να είναι τοποθετημένος μέσα στο πεδίο. <br/> Μετά από μία σάρωση, παραμένει στο πεδίο ο αριθμός του badge και αντικαθίσταται με καινούργιο στην επόμενη σάρωση από κάμερα.",
    "<strong>Προσοχή:</strong> Σε περίπτωση που θέλουμε να ξανά σαρώσουμε δύο φορές συνεχόμενα το ίδιο badge, πρέπει να πατήσουμε <strong>Καθαρισμός</strong>. <br/>Η συνεχόμενη σάρωση του ίδιου badge δε γίνεται αυτομάτως, για να μην πραγματοποιούνται πολλαπλές κλήσεις, όταν το κρατάμε μπροστά από την κάμερα.",
    "<strong>Γράψιμο αριθμού Badge σε πεδίο:</strong> Εάν έχουμε τον αριθμό ενός badge, μπορούμε να τον κάνουμε <strong>Copy Paste</strong> ή να τον γράψουμε στο πεδίο για να πραγματοποιηθεί έλεγχος της εγκυρότητάς του. <br/>Ο έλεγχος γίνεται απευθείας και το πεδίο καθαρίζει.",
    "<strong>Καθαρισμός:</strong> Πατώντας Καθαρισμός, καθαρίζουμε το πεδίο και το μήνυμα της τελευταίας σάρωσης.",
    "<strong>Μήνυμα Σάρωσης:</strong> Το μήνυμα που εμφανίζεται μετά από μία σάρωση, παραμένει στην οθόνη μέχρι να πραγματοποιηθεί επόμενη σάρωση ή να πατήσουμε Καθαρισμός. <br/> Για <strong>έγκυρο</strong> επισκέπτη εμφανίζεται μήνυμα σε πράσινο φόντο με το όνομα του ή σε πορτοκαλί φόντο, εάν εισέρχεται δεύτερη φορά στον χώρο της έκθεσης. <br/> Για <strong>μην έγκυρο</strong> επισκέπτη εμφανίζεται μήνυμα σε κόκκινο φόντο με τον λόγο απόρριψης.",
    "<strong>Έλεγχοι:</strong> Πραγματοποιούνται δύο έλεγχοι. <br/> 1) Έαν ο επισκέπτης υπάρχει στην ενεργή έκθεση.<br/> 2) Εάν η είσοδος του επισκέπτη έχει εγκριθεί από τους οργανωτές. <br/> 3) Σε περίπτωση συνδιοργάνωσης, ελέγχουμε εάν ο επισκέπτης υπάρχει σε τουλάχιστον μία από τις εκθέσεις που την αποτελούν."
]
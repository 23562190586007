// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {AXIOS_ERROR_MESSAGE} from "../../../locales/components/namespaces";

type Props = {
	errorMessage: String,
	errorCode: Number
};

const ErrorMessage = ({errorMessage, errorCode, responseMessage}: Props) => {
	const {t} = useTranslation(AXIOS_ERROR_MESSAGE)
	return (
		<span>{`${t('Message')}: ${errorMessage}`}</span>
	);
};

export default ErrorMessage
import {useEffect} from "react";
import {useParams} from "react-router";
import useExhibitionAction from "./useExhibitionAction";
import useExhibitionData from "./useExhibitionData";

export const useExhibition = ({language, id: propsId} = {}) => {
    const {id: paramsId} = useParams()
    const id = propsId ?? paramsId
    const {getExhibitionDetails} = useExhibitionAction()
    const {byId: exhibition, loading} = useExhibitionData({id, overrideLanguage: language})
    useEffect(() => {
            getExhibitionDetails(id)
    }, [getExhibitionDetails, id])
    return {
        id,
        exhibition,
        loading
    }
}
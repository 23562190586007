// @flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {
    parseExhibition
} from "../../types/exhibition/parse";
import type {
    Exhibition
} from "../../types/exhibition/types";
import {parseMessageStatus} from "../../types/badge/parse";

const selectLoading = createSelector(
    (state) => state.exhibition,
    (item) => item.get('loading')
)

const selectById = createSelector(
    [
        (state) => state.exhibition.get('byId'),
        (state) => state.currentUser.get('language'),
        (state, id) => id,
        (state, id, language) => language
    ],
    (byId, userLanguage, id, overrideLanguage) => parseExhibition(byId.get(id), overrideLanguage ?? userLanguage)
)

const selectActionOnVisitorStatus = createSelector(
    (state) => state.exhibition.get('actionOnVisitorStatus'),
    (item) => parseMessageStatus(item)
)

const useExhibitionData = ({id, overrideLanguage, exhibitorId, visitorId} = {}) => {
    const loading = useSelector(selectLoading)
    const byId: Exhibition = useSelector((state) => selectById(state, id, overrideLanguage))
    const actionOnVisitorStatus = useSelector(selectActionOnVisitorStatus)

    return {
        byId,
        loading,
        actionOnVisitorStatus
    }
}

export default useExhibitionData

import useUserData from "../../hooks/user/useUserData";
import {useCallback, useMemo} from "react";
import axios from "axios";
import {API} from "../../helpers/constants";
import {useFormikContext} from "formik";
import {useDebouncedCallback} from "./useDebouncedCallback";

export const useTextFieldChange = ({handleChange, field, value, cityFieldRef}) => {
    const {token} = useUserData()
    const {setFieldValue} = useFormikContext()
    const fetchCityCallback = useCallback((postalCode) => {
        axios({
            url: API + '/en/visitor/search-zipcode',
            method: 'post',
            data: {
                ZipCode: postalCode
            },
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        }).then((response) => {
            const {city} = response.data
            cityFieldRef.current.value = city
            setFieldValue("fieldValues.city", city)
        }).catch((error) => {

        })
    }, [setFieldValue, token])
    const callBack = useMemo(() => (v) => {
        setFieldValue("fieldValues." + field.id, v)
        fetchCityCallback(v)
    }, [fetchCityCallback, field.id, setFieldValue])
    const {value: textValue, debouncedOnChange} = useDebouncedCallback(callBack, value)
    return {
        doNotUseValueProp: true,
        handleChange: debouncedOnChange,
        value: textValue
    }

}

import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapActiveExhibitions} from "../../types/activeExhibitions/map";

const INITIAL_STATE = {
    loading: false,
    byId: {},
}

function getActiveExhibitions(state) {
    return state
        .set('loading', true)
}

function getActiveExhibitionsSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapMultiple(payload, mapActiveExhibitions)
    return state
        .set('loading', false)
        .set('byId',
            state.get('byId')
                .mergeWith(
                    preserveFieldsIfNotPresentInNew([]), fromJS(mappedData)
                ))
}

function getActiveExhibitionsFailed(state, action) {
    return state
        .set('loading', false)
}

const activeExhibitions = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_ACTIVE_EXHIBITIONS]: getActiveExhibitions,
        [ActionTypes.GET_ACTIVE_EXHIBITIONS_SUCCEEDED]: getActiveExhibitionsSucceeded,
        [ActionTypes.GET_ACTIVE_EXHIBITIONS_FAILED]: getActiveExhibitionsFailed,
    }
}

export default activeExhibitions
// @flow
import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import {ErrorMessage} from "formik";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import TextField from "@mui/material/TextField";
import {RegistrationContext} from "../../form/form";
import {useTextFieldChange} from "../../../hooks/registration/useTextFieldChange";
import SearchVisitor from "./searchVisitor";

type Props = {

};

const textfieldProps = (props, t) => {
    const {disabled, field, touched, errors} = props
    return {
        // margin: "dense",
        variant: disabled ? "standard" : "outlined",
        fullWidth: true,
        size: "small",
        sx: {
            '& .MuiOutlinedInput-input': {
                bgcolor: "background.paper"
            }
        },
        autoComplete: "chrome-off",
        required: field.required,
        error: touched["fieldValues"] &&
            touched["fieldValues"][field.id] &&
            Boolean(errors["fieldValues"] && errors["fieldValues"][field.id]),
        helperText: <ErrorMessage name={"fieldValues." + field.id}/>,
        label: t(field.name),
        disabled: disabled
    }
}

const Text = (props: Props) => {
    const {formik, values, field, value: propValue, handleBlur, handleChange, touched, errors, disabled} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    const {
        companyNameFieldRef,
        nameFieldRef,
        vatFieldRef,
        emailFieldRef,
        phoneFieldRef,
        companyAddressFieldRef,
        cityFieldRef,
        companyWebsiteFieldRef,
        instagramFieldRef,
        facebookFieldRef,
        positionFieldRef,
        personNumberFieldRef,
        postalCodeFieldRef,
        mobilePhoneFieldRef,
        enableSearchableFields,
        searchFieldsOnly
    } = useContext(RegistrationContext)
    const {handleChange: onChange, value} = useTextFieldChange({
        handleChange,
        field,
        value: propValue,
        cityFieldRef
    })

    let searchFieldRef = null
    switch (field.id) {
        case 'name':
            searchFieldRef = nameFieldRef
            break
        case 'companyName':
            searchFieldRef = companyNameFieldRef
            break
        case 'vatNumber':
            searchFieldRef = vatFieldRef
            break
        case 'mobilePhone':
            searchFieldRef = mobilePhoneFieldRef
            break
        case 'phone':
            searchFieldRef = phoneFieldRef
            break
        case 'email':
            searchFieldRef = emailFieldRef
            break
        default:
            break
    }

    let fieldRef = null
    switch (field.id) {
        case 'companyAddress':
            fieldRef = companyAddressFieldRef
            break
        case 'city':
            fieldRef = cityFieldRef
            break
        case 'companyWebsite':
            fieldRef = companyWebsiteFieldRef
            break
        case 'facebook':
            fieldRef = facebookFieldRef
            break
        case 'instagram':
            fieldRef = instagramFieldRef
            break
        case 'position':
            fieldRef = positionFieldRef
            break
        case 'personCount':
            fieldRef = personNumberFieldRef
            break
        default:
            break
    }

    return (
        <React.Fragment>
            {(searchFieldsOnly || enableSearchableFields) && field.searchable ? (
                <SearchVisitor
                    searchFieldRef={searchFieldRef}
                    formik={formik}
                    values={values}
                    field={field}
                    value={value}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    disabled={disabled}
                    textfieldProps={textfieldProps(props, t)}
                />
            ) : (
                <React.Fragment>
                    {field.id === "postalCode" ? (
                        <TextField
                            {...textfieldProps(props, t)}
                            type={field.inputType}
                            onChange={onChange}
                            InputLabelProps={{shrink: true}}
                            defaultValue={value}
                            autoComplete='off'
                            inputRef={postalCodeFieldRef}
                            inputProps={{maxLength: field?.maxLength}}
                        />
                     ) : (
                        <TextField
                            {...textfieldProps(props, t)}
                            type={field.inputType}
                            defaultValue={value}
                            onBlur={handleBlur}
                            inputRef={fieldRef}
                            autoComplete='off'
                            onChange={(e) => {
                                fieldRef.current.value = (field.type !== 'email' && field.type !== 'companyWebsite' && field.type !== 'instagram' && field.type !== 'facebook') ? normalizeGreek(fieldRef.current.value.toUpperCase()) : fieldRef.current.value
                                formik.setFieldValue('fieldValues.' + field.id, fieldRef.current.value)
                            }}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: field?.maxLength}}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    )
};

export default Text
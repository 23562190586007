// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";
import {visitorBodyParameters} from "../../helpers/helpers";
import jsonToFormData from "json-form-data";

const useExhibitionAction = () => {
    const dispatch = useDispatch()

    const getExhibitionDetails = useCallback((id) => {
        dispatch(actions.getExhibitionDetails({
            id: id
        }))
    }, [dispatch])

    const createVisitor = useCallback((exhibitionId, exhibitorId, values, isRegistration = false, isUpdate, sendMessages) => {
        const dataToSend = visitorBodyParameters({exhibitionId, exhibitorId, isRegistration, isUpdate, sendMessages}, values)
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.createVisitor(formData))
    }, [dispatch])

    const updateVisitor = useCallback((exhibitionId, visitorId, values) => {
        const dataToSend = visitorBodyParameters({exhibitionId, visitorId}, values)
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.updateVisitor(formData))
    }, [dispatch])

    const clearActionOnVisitorStatus = useCallback(() => {
        dispatch(actions.clearActionOnVisitorStatus())
    }, [dispatch])

    return {
        getExhibitionDetails,
        createVisitor,
        updateVisitor,
        clearActionOnVisitorStatus
    }
}

export default useExhibitionAction

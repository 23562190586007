// @flow
import * as React from 'react';
import {useContext, useMemo} from "react";
import {RegistrationContext} from "../form/form";
import Input from "../fieldByType/fieldByType";

type Props = {

};

const RenderField = (props: Props) => {
    const {
        formik,
        field,
        values,
        handleBlur,
        handleChange,
        touched,
        errors
    } = props

    const {enableLayout, searchFieldsOnly, limitedView} = useContext(RegistrationContext)
    const checkIfFieldVisible = useMemo(() => {
        if (!field.enabled) return
        if (searchFieldsOnly) return field.searchable
        if (!field.visibleOnRegistrationForm) return
        if (!enableLayout && field.type === 'layout_element') return
        if (field.type === 'surname') return
        return (!limitedView)
    }, [enableLayout, field.enabled, field.searchable, field.type, field.visibleOnRegistrationForm, limitedView, searchFieldsOnly])

    const value = useMemo(() => (
        values &&
        values[field.id]
    ) ? values[field.id] :
        field.type === "select" && field.multiple ? []
            : '', [field.id, field.multiple, field.type, values])
    if (!checkIfFieldVisible) return null
    return (
        <Input
            formik={formik}
            values={values}
            field={field}
            value={value}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            disabled={limitedView}
        />
    )
};

export default RenderField
import {actionCreator} from '../helpers/actionCreator'
import * as ActionTypes from './types'

export * from './currentUser/actions'
export * from './badge/actions'

export const getActiveExhibitions = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS)
export const getActiveExhibitionsSucceeded = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS_SUCCEEDED)
export const getActiveExhibitionsFailed = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS_FAILED)

export const getExhibitionDetails = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS)
export const getExhibitionDetailsSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_SUCCEEDED)
export const getExhibitionDetailsFailed = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_FAILED)

export const getCountries = actionCreator(ActionTypes.GET_COUNTRIES)
export const getCountriesSucceeded = actionCreator(ActionTypes.GET_COUNTRIES_SUCCEEDED)
export const getCountriesFailed = actionCreator(ActionTypes.GET_COUNTRIES_FAILED)

export const getInterests = actionCreator(ActionTypes.GET_INTERESTS)
export const getInterestsSucceeded = actionCreator(ActionTypes.GET_INTERESTS_SUCCEEDED)
export const getInterestsFailed = actionCreator(ActionTypes.GET_INTERESTS_FAILED)

export const getVisitorCategories = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES)
export const getVisitorCategoriesSucceeded = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES_SUCCEEDED)
export const getVisitorCategoriesFailed = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES_FAILED)

export const createVisitor = actionCreator(ActionTypes.CREATE_VISITOR)
export const createVisitorSucceeded = actionCreator(ActionTypes.CREATE_VISITOR_SUCCEEDED)
export const createVisitorFailed = actionCreator(ActionTypes.CREATE_VISITOR_FAILED)

export const updateVisitor = actionCreator(ActionTypes.UPDATE_VISITOR)
export const updateVisitorSucceeded = actionCreator(ActionTypes.UPDATE_VISITOR_SUCCEEDED)
export const updateVisitorFailed = actionCreator(ActionTypes.UPDATE_VISITOR_FAILED)

export const clearActionOnVisitorStatus = actionCreator(ActionTypes.CLEAR_ACTION_ON_VISITOR_STATUS)
import ENGLISH_FLAG from './flags/gb.png'
import GREEK_FLAG from './flags/gr.png'

export const IMAGE_LOGO = {
    en: require('./logo/rota_uk-transparent.png'),
    el: require('./logo/rota_gr-transparent.png')
}

export const DEMO_EXPO = require('./demoExpo.jpg')

const Images = {
    englishFlag: ENGLISH_FLAG,
    greekFlag: GREEK_FLAG
}

export default Images

// @flow
import * as React from 'react';
import {Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import {isArray} from "lodash";
import {ErrorMessage} from "formik";

type Props = {};

const Checkbox = (props: Props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors, disabled} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    const checked = (!isArray(value) && !!value) || (isArray(value) && value.length === 0)
    return (
        <FormControl
            error={
                touched["fieldValues"] &&
                touched["fieldValues"][field.id] &&
                Boolean(errors["fieldValues"] && errors["fieldValues"][field.id])
            }
        >
            <FormControlLabel
                control={
                    <MuiCheckbox
                        disabled={disabled}
                        name={"fieldValues." + field.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={field.required}
                        checked={checked}
                        value={checked}
                    />
                }
                error={`
                    ${touched["fieldValues"] &&
                    touched["fieldValues"][field.id] &&
                    Boolean(errors["fieldValues"] && errors["fieldValues"][field.id])}
                `}
                labelPlacement="end"
                label={t(field.name)}
            />
            <FormHelperText>
                <ErrorMessage name={"fieldValues." + field.id}/>
            </FormHelperText>
        </FormControl>
    )
};

export default Checkbox
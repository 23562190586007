import {useEffect} from "react";
import {useCategoriesAction} from "./useCategoriesAction";
import {useCategoriesData} from "./useCategoriesData";

export const useCategories = (id) => {
    const {getVisitorCategories} = useCategoriesAction(id)
    const {loading, categories} = useCategoriesData()
    useEffect(() => {
        getVisitorCategories(id)
    }, [getVisitorCategories, id])
    return {
        categories,
        loading
    }
}
import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {SCANNER_ERROR_SOUND, SCANNER_SUCCESS_SOUND} from "../../assets/sounds";

const INITIAL_STATE = {
    loading: false,
    scannedBadgeId: null,
    badgeById: {},
    badgeConfigurationByExhibitionId: {},
    scanBadgeStatus: null
}

function clearScannedBadge(state, action) {
    return state
        .set('scannedBadgeId', null)
        .set('scanBadgeStatus', null)
}

function fastScanBadge(state) {
    return state
        .set('loading', true)
}

function fastScanBadgeSucceeded(state, action) {
    const {payload: {Result, Name}} = action
    let message = ''
    let type = 'error'
    switch (Result) {
        case 1:
            message = Name
            type = 'success'
            break;
        case 2:
            message = 'Code not valid. Visitor not found in current exhibition.'
            break;
        case 3:
            message = 'Visitor badge already scanned.'
            type = 'warning'
            break;
        case 4:
            message = 'Visitor not approved for entry.'
            break;
        default:
            break;
    }
    if(Result === 2 || Result === 4) {
        const errorAudio = new Audio(SCANNER_ERROR_SOUND);
        errorAudio.play();
    }
    if(Result === 1 || (Name && !(Result === 2) && !(Result === 4))) {
        const successAudio = new Audio(SCANNER_SUCCESS_SOUND);
        successAudio.play();
    }
    return state
        .set('loading', false)
        .set('scanBadgeStatus', fromJS({
            time: new Date(),
            message: message,
            type: type,
            name: Name
        }))
}

function fastScanBadgeFailed(state, action) {
    const {message} = action.payload
    return state
        .set('loading', false)
        .set('scanBadgeStatus', fromJS({type: 'error', message: message}))
}

const badge = {
    initialState: INITIAL_STATE, handlers: {
        [ActionTypes.FAST_SCAN_BADGE]: fastScanBadge,
        [ActionTypes.FAST_SCAN_BADGE_SUCCEEDED]: fastScanBadgeSucceeded,
        [ActionTypes.FAST_SCAN_BADGE_FAILED]: fastScanBadgeFailed,
        [ActionTypes.CLEAR_SCANNED_BADGE]: clearScannedBadge,
    }
}

export default badge

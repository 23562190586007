import type { MessageStatus } from "./types";

export const parseMessageStatus: MessageStatus = (datum) => {
    if (!datum) return
    return {
        time: datum.get('time'),
        type: datum.get('type'),
        message: datum.get('message'),
        name: datum.get('name'),
        parameters: datum.has('parameters') ?
            datum.get('parameters').toJS() : null,
    }
}

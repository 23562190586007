//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple} from "../../helpers/parse";
import type { Interests } from "../../types/interests/type";
import _ from "lodash";
import {parseInterests} from "../../types/interests/parse";

const selectLoading = createSelector(
    (state) => state.interests.get('loading'),
    (item) => item
)

const selectItems = createSelector(
    [
        (state) => state.interests.get('byId'),
        (state) => state.currentUser.get('language')
    ],
    (byId, userLanguage) => parseMultiple(byId, (i) => parseInterests(i, userLanguage))
)

export const useInterestsData = () => {
    const loading: boolean = useSelector(selectLoading)
    const interests: Array<Interests> = useSelector(selectItems)
    const sortedInterests = _.sortBy(interests, ['description'])
    return {
        loading,
        sortedInterests
    }
}
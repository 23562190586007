//@flow
import React from 'react';
import {Alert, Snackbar} from "@mui/material";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import type {MessageStatus} from "../../../types/badge/types";


type Props = {
    status: MessageStatus,
    onClose: Function,
    autoHideDuration?: number
}

const ActionStatus = (props: Props) => {
    const {status, onClose, autoHideDuration = 3000} = props

    const {t} = useTranslation(EXHIBITION)
    return (
        <Snackbar
            open={Boolean(status)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            autoHideDuration={autoHideDuration}
            onClose={onClose}>
            <Alert
                onClose={onClose}
                severity={status?.type}
                sx={{width: '100%'}}
            >
                {status ? t(status.message, status.parameters ?? {}) : ''}
            </Alert>
        </Snackbar>
    )
}

export default ActionStatus
// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";

export const useCountriesAction = () => {
    const dispatch = useDispatch()
    const getCountries = useCallback( () => {
        dispatch(actions.getCountries({}))
    }, [dispatch])

    return {
        getCountries
    }
}
//@flow
import React, {useState} from 'react';
import Box from "@mui/material/Box";
import Form from "../../components/form/form";
import {useExhibition} from "../../hooks/exhibitions/useExhibition";
import {useCountries} from "../../hooks/countries/useCountries";
import {useInterests} from "../../hooks/interests/useInterests";
import useExhibitionData from "../../hooks/exhibitions/useExhibitionData";
import useExhibitionAction from "../../hooks/exhibitions/useExhibitionAction";
import ActionStatus from "../../components/alerts/actionStatus/actionStatus";
import SelectFromOngoingExhibition from "../../components/selectFromOngoingExhibition/selectFromOngoingExhibition";
import {useCategories} from "../../hooks/categories/useCategories";

const RegistrationInner = ({selectedExhibition}) => {
    const {id, exhibition} = useExhibition({
        id: selectedExhibition
    })
    const {countries, phoneCodes, defaultCountry, defaultPhoneCode} = useCountries()
    const {interests} = useInterests(id)
    const {categories} = useCategories(id)
    const {actionOnVisitorStatus} = useExhibitionData()
    const {clearActionOnVisitorStatus} = useExhibitionAction()
    return (
        <Box
            sx={{
                textAlign: 'center',
                width: '100%',
                p: '20px 10px 10px 10px',
            }}
        >
            {exhibition && countries && phoneCodes && defaultCountry && defaultPhoneCode &&
                <Form
                    id={id}
                    exhibition={exhibition}
                    countries={countries}
                    interests={interests}
                    phoneCodes={phoneCodes}
                    defaultCountry={defaultCountry}
                    defaultPhoneCode={defaultPhoneCode}
                    categories={categories}
                />
            }
            <ActionStatus
                status={actionOnVisitorStatus}
                onClose={clearActionOnVisitorStatus}
            />
        </Box>
    )
}

const Registration = () => {
    const [selectedExhibition, setSelectedExhibition] = useState(null)
    return (
        <>
            <SelectFromOngoingExhibition
                value={selectedExhibition}
                onSubmit={setSelectedExhibition}
            />
            {selectedExhibition && (
                <RegistrationInner
                    selectedExhibition={selectedExhibition}a
                />
            )}
        </>
    )
}

export default Registration

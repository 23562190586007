const mode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : 'production'

export const configuration = window.GLOBAL_CONFIGURATION[mode]

export const API = mode === 'production' ? configuration.API_HOST[window.location.hostname] : configuration.API_HOST;
export const PortalToken = window.GLOBAL_CONFIGURATION.portalToken

export const EXHIBITION_IMAGE_RELATIVE_PATH = '/attachments/exhLogos/'
export const EXHIBITION_FILES_RELATIVE_PATH = '/exhibition/attachment/'

export const Languages = {
    EL: 'el',
    EN: 'en',
}

export const Permissions = {
    BeExhibitor: "EXHIBITOR_CONTENT",
    BeOrganizer: "ORGANIZER_CONTENT",
    BeLobbyUser: "LOBBY_CONTENT",
    SeeReports: "REPORTS_CONTENT"
}

export const CatalogueLanguages = {
    ALL: 0,
    EN: 1,
    EL: 2
}

export const PaperSize = {
    A4: 1,
    A5: 2,
    A6: 4,
    LABEL: 5
}

export const Roles = {
    Organizer: 1,
    Exhibitor: 2,
    Visitor: 3,
}


export const RoleTranslations = {
    [Roles.Visitor]: {
        en: 'Visitor',
        el: 'Επισκέπτης',
    },
    [Roles.Exhibitor]: {
        en: 'Exhibitor',
        el: 'Εκθέτης',
    },
    [Roles.Organizer]: {
        en: 'Organizer',
        el: 'Οργανωτής',
    },
}

export const LANG = 'el'

export const API_URL = 'http://localhost:8090'

export const API_LURL = `${API_URL}/${LANG}/`

export const DATE_FORMAT = 'd-m-Y'
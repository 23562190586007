import * as yup from "yup";
import {useFormik} from "formik";
import {defaultPreregistrationFields} from "../../components/customizeRegistration/defaultFields";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";
import useLocalStorageSync from "./useLocalStorageSync";
import {useEffect, useMemo} from "react";
import _ from 'lodash'
import {CatalogueLanguages} from "../../helpers/constants";

export const usePreregistrationForm = ({
                                           exhibition,
                                           onSubmit,
                                           useLocalStorage,
                                           isFromRegistration,
                                           countries,
                                           defaultCountry,
                                           defaultPhoneCode
                                       }) => {
    const {t} = useTranslation(EXHIBITION)
    const exhibitionPreregistrationFields = useMemo(() => exhibition && exhibition
        .preregistrationFormFields ? exhibition
        .preregistrationFormFields : [], [exhibition])

    const validationSchema = yup.object({
        fieldValues: yup.object(
            exhibitionPreregistrationFields
                .reduce((p, n) => {
                    if (!(n.enabled)) return p
                    if (!n.visibleOnRegistrationForm) return p
                    return {
                        ...p,
                        [n.id]: (
                            n.type === "name" ? (exhibition?.catalogueLanguage === CatalogueLanguages.EN? yup.string().required(t('required')).matches(/^([A-Za-z^!@#$%&*()_+\-\\|{}[\].,<>?'":;/`=\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\w]*)$/gi, t('Only latin and special characters allowed.')) : yup.string().required(t('required'))) :
                                n.type === "companyName" || n.type === "address" || n.type === "postalCode" || n.type === "vat" || n.type === 'position' || n.type === 'surname' ? (exhibition?.catalogueLanguage === CatalogueLanguages.EN ? yup.string().when(["isIndividual"], {
                                        is: (isIndividual) => isIndividual !== true && n.required === true,
                                        then: yup.string().notRequired(),
                                        // then: yup.string().required(t('required')),
                                        else: yup.string().notRequired(),
                                    }).matches(/^([A-Za-z^!@#$%&*()_+\-\\|{}[\].,<>?'":;/`=\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\w]*)$/gi, t('Only latin and special characters allowed.')) : yup.string().when(["isIndividual"], {
                                        is: (isIndividual) => isIndividual !== true && n.required === true,
                                        then: yup.string().notRequired(),
                                        // then: yup.string().required(t('required')),
                                        else: yup.string().notRequired(),
                                    })) :
                                    n.type === "city" || n.type === "companyWebsite" || n.type === "facebook" || n.type === "instagram" ? yup.string().when(["isIndividual"], {
                                            is: (isIndividual) => isIndividual !== true && n.required === true,
                                            // then: yup.string().required(t('required')),
                                            then: yup.string().notRequired(),
                                            else: yup.string().notRequired(),
                                        }) :
                                        n.type === "email" ? yup.string().when(["isIndividual"], {
                                                is: (isIndividual) => isIndividual !== true && n.required === true,
                                                then: yup.string().email(t('Enter a valid email')).required(t('required')),
                                                else: yup.string().email(t('Enter a valid email')).notRequired(),
                                            }) :
                                            n.type === "mobile" ?
                                                yup.number().positive(t('Enter a valid phone')).integer(t('Enter a valid phone')).test(
                                                    'Enter a valid phone',
                                                    t('Enter a valid phone'),
                                                    function (value) {
                                                        if (this.parent['isIndividual'] !== true && n.required === true) {
                                                            return value && String(value).length >= 8
                                                        } else {
                                                            return value ? String(value).length >= 8 : true
                                                        }
                                                    }
                                                ) :
                                                n.type === "tel" ?
                                                    yup.number().positive(t('Enter a valid phone')).integer(t('Enter a valid phone')).test(
                                                        'Enter a valid phone',
                                                        t('Enter a valid phone'),
                                                        function (value) {
                                                            return value ? String(value).length >= 8 : true
                                                        }
                                                    ) :
                                                    n.type === "personCount" ?
                                                        yup.number().when(["isIndividual"], {
                                                            is: (isIndividual) => isIndividual !== true && n.required === true,
                                                            // then: yup.number().positive().integer().required(t('required')),
                                                            then: yup.number().positive().integer().notRequired(),
                                                            else: yup.number().positive().integer().notRequired(),
                                                        }) :
                                                        n.type === "isIndividual" ?
                                                            yup.mixed().test(
                                                                'acceptedValues', t('Field must be checked'), function (value) {
                                                                    return n.required !== true;
                                                                }
                                                            ) :
                                                            n.type === "agreeToTerms" ?
                                                                yup.bool().oneOf([true], t('Field must be checked')) :
                                                                n.type === "hasRevisited" ?
                                                                    yup.bool().oneOf([true, false], t('Field must be checked')) :
                                                                    n.type === "country" ? yup.mixed().test(
                                                                            'required', t('required'), function (value) {
                                                                                // if (n.required === true) {
                                                                                //     return value && value?.id
                                                                                // } else {
                                                                                return true
                                                                                // }
                                                                            }
                                                                        ) :
                                                                        n.type === "characterization" ? yup.mixed().test(
                                                                                'required', t('required'), function (value) {
                                                                                    // if (n.required === true) {
                                                                                    //     return value && value?.id
                                                                                    // } else {
                                                                                    return true
                                                                                    // }
                                                                                }
                                                                            ) :
                                                                            n.type === "interests" ? (n.multiple === true ? yup.mixed().test(
                                                                                    'required', t('Select at least one'), function (value) {
                                                                                        // if (this.parent['isIndividual'] === false && n.required === true) {
                                                                                        //     return value.length > 0 || (value.length === 0 && n.addNew === true && this.parent['otherInterests'] && this.parent['otherInterests'].length > 0)
                                                                                        // } else {
                                                                                        return true
                                                                                        // }
                                                                                    }) : yup.mixed().test(
                                                                                    'required', t('Select only one'), function (value) {
                                                                                        // if (this.parent['isIndividual'] === false && n.required === true) {
                                                                                        //     return (value.length === 1 && !n.addNew) || (value.length === 1 && n.addNew === true && !this.parent['otherInterests']) || (value.length === 0 && n.addNew === true && this.parent['otherInterests'] && this.parent['otherInterests'].length > 0)
                                                                                        // } else {
                                                                                        return true
                                                                                        // }
                                                                                    })) :
                                                                                yup.string().when(["isIndividual"], {
                                                                                    is: (isIndividual) => isIndividual !== true && n.required === true,
                                                                                    // then: yup.string().required(t('required')),
                                                                                    then: yup.string().notRequired(),
                                                                                    else: yup.string().notRequired(),
                                                                                })
                        )
                    }
                }, {}))
    })

    const formFields = useMemo(() => {
        const merged = _.merge(
            _.keyBy(defaultPreregistrationFields, 'id'),
            _.keyBy(exhibitionPreregistrationFields, 'id')
        )
        const result = _.values(merged)
        return _.orderBy(result, 'weight', 'asc')
    }, [exhibitionPreregistrationFields])

    const formValues = useMemo(() => ({
        ...defaultPreregistrationFields
            .reduce((p, n) => {
                let initialValue = ''
                if (n.id === 'checkbox') initialValue = false
                if (n.id === 'interests') initialValue = []
                if (n.id === 'countries') initialValue = defaultCountry
                if (n.id === 'personCount') initialValue = 1
                if (n.id === 'mobilePhone') initialValue = 30
                return {
                    [n.id]: initialValue,
                    ...p,
                }
            }, {}),
        countryPhoneCode: defaultPhoneCode,
        otherInterests: ''
    }), [defaultCountry, defaultPhoneCode])

    const formik = useFormik({
        initialValues: {
            exhibitionId: exhibition?.id,
            fields: formFields,
            fieldValues: formValues
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    const {
        value: localStorageFormik,
        handleChange: handleFieldValuesChange
    } = useLocalStorageSync('visitorInfo')

    useEffect(() => {
        if (useLocalStorage) {
            handleFieldValuesChange(formik)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.fieldValues, useLocalStorage])

    const fieldValues = useMemo(() => (
        useLocalStorage &&
        localStorageFormik &&
        localStorageFormik.values
    ) ? localStorageFormik.values.fieldValues : formik.values.fieldValues, [formik.values.fieldValues, localStorageFormik, useLocalStorage])

    return {
        formik,
        fieldValues,
        fields: formFields,
        exhibitionPreregistrationFields
    }
}

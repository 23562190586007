//@flow
import React, {useContext} from 'react';
import {API} from "../../helpers/constants";
import {AuthenticatedFile} from "../authenticatedLink/authenticatedFile";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import {printFile} from "../../helpers/fileHandle";
import {Print} from "@mui/icons-material";
import {TitleContext} from "../../pages/main/content";

type Props = {
    isPreview?: boolean,
    autoReloadFile?: boolean,
    userId: string,
    printSize?: string,
    type: "visitor" | "partner"
}

const ButtonComponent = ({
                             loading,
                             fetchFile,
                             localLink,
                             autoReloadFile,
                             component: Component
                         }) => {
    const onClick = (evt) => {
        evt.stopPropagation()
        if (!autoReloadFile) {
            fetchFile()
        } else {
            printFile(localLink)
        }
    }
    return loading ? (
        <CircularProgress
            color="inherit"
            size={16}
            sx={{mx: 1.25, alignSelf: "center"}}
        />
    ) : (!autoReloadFile || (autoReloadFile && localLink)) ? (
        Component ? (
            <Component
                onClick={onClick}
            />
        ) : (
            <IconButton
                color="primary"
                onClick={onClick}
            >
                <Print/>
            </IconButton>
        )
    ) : null;
}

export const getPdfBadgeUrl = (type, previewPart, printSize, id) => {
    return API + `/en/${type}/badge${previewPart}/${printSize}/${id}`;
}

const PrintBadgeButton = (props: Props) => {
    const {userId, type, autoReloadFile, isPreview, component} = props
    const {printSize: contextSize} = useContext(TitleContext)
    const printSize = props.printSize ?? contextSize
    const previewPart = isPreview ? '/preview' : ''
    const url = getPdfBadgeUrl(type, previewPart, printSize, userId)

    return (
        <AuthenticatedFile
            autoReloadFile={autoReloadFile}
            url={url}
            automaticallyUsePrintFile={true}
        >
            {(params) => (
                <ButtonComponent
                    {...params}
                    component={component}
                    autoReloadFile={autoReloadFile}
                />
            )}
        </AuthenticatedFile>
    )
}

export default PrintBadgeButton;

import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";

const NotFound = () => {
    const {t} = useTranslation(EXHIBITION)
    return (
        <Typography sx={{py: 4, textAlign: "center"}} variant={"h6"}>
            {t('PAGE NOT FOUND')}
        </Typography>
    )
}

export default NotFound;

//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple} from "../../helpers/parse";
import type { ActiveExhibitions } from "../../types/activeExhibitions/types";
import {parseActiveExhibitions} from "../../types/activeExhibitions/parse";

const selectLoading = createSelector(
    (state) => state.activeExhibitions.get('loading'),
    (item) => item
)

const selectItems = createSelector(
    [
        (state) => state.activeExhibitions.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (byId, userLanguage) =>
        parseMultiple(byId, (i) => parseActiveExhibitions(i, userLanguage)
    )
)

export const useActiveExhibitionsData = () => {
    const loading: boolean = useSelector(selectLoading)
    const activeExhibitions: Array<ActiveExhibitions> = useSelector(selectItems)
    return {
        loading,
        activeExhibitions
    }
}
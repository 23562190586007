// @flow
import React, {useMemo} from 'react';
import useLocalStorageSync from "../../hooks/registration/useLocalStorageSync";
import {Card, CardContent, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../locales/components/namespaces";
import Box from "@mui/material/Box";
import _ from "lodash";

const ViewScreen = () => {
	const {
		value: localStorageFormik
	} = useLocalStorageSync('visitorInfo')
	const {t} = useTranslation(VISITOR_PROFILE)
	const fields = useMemo(() => localStorageFormik?.values?.fields.filter(f => f.enabled && f.visibleOnConfirmationDisplay && f.visibleOnRegistrationForm).map(field => ({
		...field,
		value: localStorageFormik?.values?.fieldValues[field.id]
	})), [localStorageFormik?.values?.fieldValues, localStorageFormik?.values?.fields])
	const getFieldValue = (type, value) => {
		switch (type) {
			case 'companyName':
			case 'name':
			case 'surname':
			case 'vat':
			case 'email':
			case 'mobile':
			case 'tel':
			case 'address':
			case 'postalCode':
			case 'city':
			case 'companyWebsite':
			case 'instagram':
			case 'facebook':
			case 'position':
			case 'personCount':
				return value
			case 'country':
			case 'characterization':
				return value?.description
			case 'interests':
				return value.reduce((accumulator, currentValue) => (accumulator ? accumulator + ', ' + currentValue.description : currentValue.description), '')
			case 'agreeToTerms':
			case 'isIndividual':
			case 'hasRevisited':
				return value ? t('YES') : t('NO')
			default:
				return ''
		}
	}
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				m: 4,
			}}
		>
			<Card raised sx={{width: {xs: '99%', sm: '99%', md: '90%', lg: '80%'}}}>
				<CardContent>
					{
						_.orderBy(fields?.map(field => (
							<Grid container spacing={1} key={field.id} sx={{pb: 1, pt: 1, borderBottom: '1px solid #9DC2E5'}}>
								<Grid item xs={12} sm={6} sx={{fontSize: '1.3rem'}}>
									{t(field.name)}
								</Grid>
								<Grid item xs={12} sm={6} sx={{fontWeight: 'bold', fontSize: '1.4rem', wordBreak: 'break-word'}}>
									{field.type === 'country' ? (
											<Box sx={{'& > img': {mr: 2, flexShrink: 0}}}>
												<img
													loading="lazy"
													width="24"
													srcSet={`${field.value?.image} 2x`}
													src={field.value?.image}
													alt=""
												/>
												{getFieldValue(field.type, field.value)}
											</Box>
									) :
										<>{getFieldValue(field.type, field.value)}</>
									}
								</Grid>
							</Grid>
						)), 'weight')
					}
				</CardContent>
			</Card>
		</Box>
	)
};

export default ViewScreen
// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";

export const useActiveExhibitionsAction = () => {
    const dispatch = useDispatch()

    const getActiveExhibitions = useCallback(() => {
        dispatch(actions.getActiveExhibitions({}))
    }, [dispatch])

    return {
        getActiveExhibitions
    }
}
import {useCallback, useContext, useState} from "react";
import useUserData from "../../hooks/user/useUserData";
import axios from "axios";
import {API} from "../../helpers/constants";
import {searchFieldMappings} from "../../helpers/helpers";
import {mapMultiple} from "../../helpers/map";
import _ from "lodash";
import {mapVisitor, mapVisitorDataToPreregistrationFormValues} from "../../types/exhibition/map";
import {RegistrationContext} from "../../components/form/form";

export const useSearchVisitor = (fieldId) => {
    const {
        onVisitorSelect,
        id: exhibitionId,
        searchOnlyAmongExhibitionVisitors
    } = useContext(RegistrationContext)
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const {token} = useUserData()

    const handleOptions = (newOptions) => {
        setOptions(newOptions)
    }

    const fetchVisitors = useCallback((textValue) => {
        setLoading(true)
        axios({
            url: API + '/en/visitor/search',
            method: 'post',
            data: {
                ExhibitionId: exhibitionId,
                ...(searchOnlyAmongExhibitionVisitors ? {SearchOnlyAmongExhibitionVisitors: searchOnlyAmongExhibitionVisitors === true ? 1 : 0} : {}),
                filters: [
                    {Name: searchFieldMappings[fieldId], Value: textValue}
                ]
            },
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        }).then((response) => {
            const {data} = response
            const mapFunction = searchOnlyAmongExhibitionVisitors ?
                mapVisitor :
                (i) => ({
                    // id: i.ContactId,
                    ...mapVisitorDataToPreregistrationFormValues(i)
                })
            // debugger
            const mappedData = mapMultiple(data, (i) => ({
                ...mapFunction(i),
                // id: i.id,
                // existsInExhibition: i.existsInExhibition === "1",
            }))

            const mappedDataArray = _.values(mappedData)
            // console.log('MappedDataArray', mappedDataArray)
            // const newOptions = _.unionBy(options, mappedDataArray, 'id')
            // console.log('newOptions', newOptions)
            setLoading(false)
            // console.log(newOptions)
            setOptions(mappedDataArray)
        }).catch((error) => {
            setLoading(false)
        })
    }, [exhibitionId, searchOnlyAmongExhibitionVisitors, fieldId, token, options])
    return {
        loading,
        options,
        fetchVisitors,
        onVisitorSelect,
        handleOptions
    }
}

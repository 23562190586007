import type {Countries, UnmappedCountries} from "./type";

export const mapCountries: Array<Countries> = (datum: Array<UnmappedCountries>) => {
    if (!(datum && datum.length > 0)) return []
    return datum.map(d => ({
        id: d.id,
        description: {
            en: d.DescriptionEN.trim(),
            el: d.Description.trim()
        },
        countryCode: d.Code,
        phoneCode: d.PhoneCode,
        image: d.ImagePath
    }))
}
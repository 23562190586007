// @flow
import React from 'react'
import "./highlightedString.css"

type Props = {
    text: string,
    query: string
}

export const getMatches = (text, query) => text.match(new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'ig'))

const HighlightedString = (props: Props) => {
    const {text = '', query} = props;
    let newTitle = text
    if (query.length > 1) {
        const matches = getMatches(text, query)
        let start = 0
        if (matches) {
            matches.map((match) => {
                start = newTitle.indexOf(match, start === 0 ? start : start + '<span class="text--highlighted">'.length + match.length + '</span>'.length)
                const length = match.length
                newTitle = newTitle.slice(0, start) + '<span class="text--highlighted">' + match + '</span>' + newTitle.slice(start + length)
                return newTitle
            })
        }
    }
    return <span dangerouslySetInnerHTML={{__html: newTitle}}/>
}

export default HighlightedString;
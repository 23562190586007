/* eslint-disable react-hooks/exhaustive-deps */
//@flow
import React, {useEffect} from 'react'
import useUserData from "../../hooks/user/useUserData";
import 'moment/locale/el'
import useUserAction from "../../hooks/user/useUserAction";
import './main.css';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {IMAGE_LOGO} from "../../assets/images";
import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOGIN} from "../../routers/routes";
import {Link} from "react-router-dom";
import Content from "./content";
import {useMultilingualText} from "../../hooks/useMultilingualText";
import LanguageSelect from "../../components/languageSelect/languageSelect";
import moment from "moment";

const Main = () => {
    const { getUserPermissions } = useUserAction()
    const {isLoggedIn, language} = useUserData()
    useEffect(() => {
        moment.locale(language);
    }, [language])
    useEffect(() => {
        if (isLoggedIn) {
            getUserPermissions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserPermissions, isLoggedIn])
    const imageUrl = useMultilingualText(IMAGE_LOGO)

    if (!isLoggedIn) return (
        <div>
            <Toolbar>
                <Link to={getRouteUrl(ROUTE_PAGE_LOGIN)} style={{display: 'inline-flex'}}>
                    <img src={imageUrl} alt="logo" style={{maxWidth: 160}}/>
                </Link>
                <Box sx={{flexGrow: 1}}/>
                <LanguageSelect/>
            </Toolbar>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <UnauthenticatedRouter/>
            </Box>
        </div>
    )
    return <Content/>
}

export default Main
//@flow
import React, {useContext} from 'react';
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import AccountMenu from "../../components/accountmenu/accountMenu";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../routers/routes";
import {IMAGE_LOGO} from "../../assets/images";
import {useHistory} from "react-router-dom";
import {TitleContext} from "./content";
import LanguageSelect from "../../components/languageSelect/languageSelect";
import {useMultilingualText} from "../../hooks/useMultilingualText";
import useUserData from "../../hooks/user/useUserData";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";

export const Title = ({after, children, showLogo}) => {
    const {title, toolbar} = useContext(TitleContext)
    const {loginName} = useUserData()
    const {t} = useTranslation(EXHIBITION)
    return (
        <React.Fragment>
            <Toolbar
                sx={{
                    position: "relative",
                    pl: showLogo ? "184px!important" : 0,
                }}
            >
                {children}
                {showLogo && (
                    <Box
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                            display: "flex",
                            bottom: -1,
                            width: "160px",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                    >
                        <Logo/>
                    </Box>
                )}
                {title}
                <Box sx={{flexGrow: 1}}/>
                {after}
                <Typography
                    variant="inherit"
                    sx={{
                        color: '000000',
                        fontWeight: 800,
                        paddingRight: '8px',
                    }}
                >
                    {t('User')}: {`${loginName}`}
                </Typography>
                <LanguageSelect/>
                <AccountMenu/>
            </Toolbar>
            {toolbar && (
                <Toolbar variant={"dense"}>
                    {toolbar}
                </Toolbar>
            )}
        </React.Fragment>
    )
}

export const Logo = () => {
    const history = useHistory()
    const imageUrl = useMultilingualText(IMAGE_LOGO)
    return (
        <img
            onClick={() => history.push(getRouteUrl(ROUTE_PAGE_HOME))}
            src={imageUrl}
            alt="logo"
            style={{marginRight: "auto", maxWidth: 160, cursor: "pointer"}}
        />
    )
}
// @flow
import * as React from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {scanInfo} from "../../helpers/info";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from '@mui/icons-material/Circle';

type Props = {};
const ScanInfo = (props: Props) => {
    return (
        <Box sx={{width: '100%', minWidth: '300px', bgcolor: 'background.paper'}}>
            <List>
                {scanInfo.map((item, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{alignItems: 'baseline', borderBottom: '1px solid #bdbdbd'}}
                        >
                            <ListItemIcon sx={{
                                minWidth: '20px'
                            }}>
                                <CircleIcon
                                    sx={{
                                        fontSize: '10px',
                                        minWidth: '20px'
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText>
                                <span dangerouslySetInnerHTML={{__html: item}}/>
                            </ListItemText>
                        </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default ScanInfo
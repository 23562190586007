import * as ActionTypes from '../../actions/types'
import {mapUser} from "../../types/user/map";
import {fromJS} from "immutable";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOGIN} from "../../routers/routes";

const INITIAL_STATE = {
    loading: false,
    errorLogin: null,
    errorRegister: null,
    registeredSuccessfully: null,
    token: null,
    tokenExpirationDateTime: null,
    data: null,
    isLoggedIn: false,
    language: 'en',
}

function userLogin(state) {
    return state
        .set('errorLogin', null)
        .set('token', null)
        .set('loading', true)
        .set('selectedStore', null)
        .set('isLoggedIn', false)
}

function userLoginSucceeded(state, action) {
    const {access_token, expires_in, locale, loginname, username} = action.payload
    const tokenExpirationDateTime = new Date()
    tokenExpirationDateTime.setSeconds(tokenExpirationDateTime.getSeconds() + expires_in)
    const user = mapUser({
        locale: locale,
        LoginName: loginname,
        username: username
    })
    return state
        .set('errorLogin', null)
        .set('loading', false)
        .set('token', access_token)
        .set('tokenExpirationDateTime', tokenExpirationDateTime)
        .set('isLoggedIn', true)
        .set('data', fromJS(user));
}

function userLoginFailed(state, action) {
    const {message} = action.payload
    return state
        .set('errorLogin', message)
        .set('token', null)
        .set('loading', false)
        .set('isLoggedIn', false)
}
function setUserLanguage(state, action) {
    return state
        .set('language', action.payload);
}

function userLogoutSucceeded(state, action) {
    window.location.href = getRouteUrl(ROUTE_PAGE_LOGIN)
}

const currentUser = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.USER_LOGIN]: userLogin,
        [ActionTypes.USER_LOGIN_SUCCEEDED]: userLoginSucceeded,
        [ActionTypes.USER_LOGIN_FAILED]: userLoginFailed,
        [ActionTypes.USER_LOGOUT_SUCCEEDED]: userLogoutSucceeded,
        [ActionTypes.SET_USER_LANGUAGE]: setUserLanguage,
    }
}

export default currentUser
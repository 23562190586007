// @flow
import React from 'react';
import HomeGrid from "../../components/home/homeGrid";

const Home = () => {
    return (
        <HomeGrid/>
    )
}

export default Home
//@flow
import React, {useEffect} from 'react';
import SelectExhibitionDialog from "./selectExhibition";
import {useActiveExhibitionsData} from "../../hooks/activeExhibitions/useActiveExhibitionsData";
import {useActiveExhibitionsAction} from "../../hooks/activeExhibitions/useActiveExhibitionsAction";

const SelectFromOngoingExhibition = ({value, onClose, onSubmit}) => {
    const {activeExhibitions, loading} = useActiveExhibitionsData()
    const {getActiveExhibitions} = useActiveExhibitionsAction()
    useEffect(() => {
        getActiveExhibitions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getActiveExhibitions])

    return (
        <SelectExhibitionDialog
            keepMounted
            open={!Boolean(value)}
            options={activeExhibitions}
            loading={loading}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    )
}

export default SelectFromOngoingExhibition;
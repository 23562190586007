//@flow
import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import {Title} from "../../pages/main/common";
import Toolbar from "@mui/material/Toolbar";
import ContentRouter from "../../routers/contentRouter";
import {TitleContext} from "../../pages/main/content";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const MenuWithDrawer = () => {
    const {toolbar} = useContext(TitleContext)

    return (
        <Box>
            <AppBar
                elevation={0}
                color={"secondary"}
                position="fixed"
            >
                <Title showLogo={true} />
            </AppBar>
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'grey.100', p: 2, minHeight: "100vh", pt: toolbar ? 8 : 2, pl: '1px', pr: '1px'}}
            >
                <Toolbar variant={"dense"}/>
                <ContentRouter/>
            </Box>
        </Box>

    )
}

export default MenuWithDrawer;
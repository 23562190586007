//@flow
import * as React from 'react';
import {Skeleton} from "@mui/material";
import NotFound from "../../pages/notFound/notFound";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
    loading: boolean,
    notFoundWhenNotLoading?: boolean,
    notFoundComponent?: React.Node,
    useCircularLoader?: boolean
}

const ContentLoader = (props: Props) => {
    const {loading, notFoundWhenNotLoading, notFoundComponent, useCircularLoader} = props
    if (!loading && !notFoundWhenNotLoading) return null
    if (!loading && notFoundWhenNotLoading) return notFoundComponent ?? <NotFound/>
    return (
        <Box sx={{width: "100%", py: 2}}>
            {useCircularLoader ? (
                <Box sx={{
                    width: "100%",
                    display: 'flex', minHeight: 100, justifyContent: "center", alignItems: "center"
                }}>
                    <CircularProgress/>
                </Box>
            ) : (
                <React.Fragment>
                    <Skeleton/>
                    <Skeleton animation="wave"/>
                    <Skeleton animation={false}/>
                </React.Fragment>
            )}
        </Box>
    )
}

export default ContentLoader
export * from "./currentUser/types"
export * from "./badge/types"

export const GET_ACTIVE_EXHIBITIONS = 'GET_ACTIVE_EXHIBITIONS'
export const GET_ACTIVE_EXHIBITIONS_SUCCEEDED = 'GET_ACTIVE_EXHIBITIONS_SUCCEEDED'
export const GET_ACTIVE_EXHIBITIONS_FAILED = 'GET_ACTIVE_EXHIBITIONS_FAILED'

export const GET_EXHIBITION_DETAILS = 'GET_EXHIBITION_DETAILS'
export const GET_EXHIBITION_DETAILS_SUCCEEDED = 'GET_EXHIBITION_DETAILS_SUCCEEDED'
export const GET_EXHIBITION_DETAILS_FAILED = 'GET_EXHIBITION_DETAILS_FAILED'

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_SUCCEEDED = 'GET_COUNTRIES_SUCCEEDED'
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED'

export const GET_INTERESTS = 'GET_INTERESTS'
export const GET_INTERESTS_SUCCEEDED = 'GET_INTERESTS_SUCCEEDED'
export const GET_INTERESTS_FAILED = 'GET_INTERESTS_FAILED'

export const GET_VISITOR_CATEGORIES = 'GET_VISITOR_CATEGORIES'
export const GET_VISITOR_CATEGORIES_SUCCEEDED = 'GET_VISITOR_CATEGORIES_SUCCEEDED'
export const GET_VISITOR_CATEGORIES_FAILED = 'GET_VISITOR_CATEGORIES_FAILED'

export const CREATE_VISITOR = 'CREATE_VISITOR'
export const CREATE_VISITOR_SUCCEEDED = 'CREATE_VISITOR_SUCCEEDED'
export const CREATE_VISITOR_FAILED = 'CREATE_VISITOR_FAILED'

export const UPDATE_VISITOR = 'UPDATE_VISITOR'
export const UPDATE_VISITOR_SUCCEEDED = 'UPDATE_VISITOR_SUCCEEDED'
export const UPDATE_VISITOR_FAILED = 'UPDATE_VISITOR_FAILED'

export const CLEAR_ACTION_ON_VISITOR_STATUS = 'CLEAR_ACTION_ON_VISITOR_STATUS'
import axios from 'axios'
import {ENABLED_MODULES} from '../../../App'
import {getRouteUrl} from '../../../helpers/getRouteUrl'
import _ from 'lodash'
import {API} from "../../../helpers/constants";
import {userLogout} from "../../../actions/actions";

const requestHandler = ENABLED_MODULES.getRequestHandlers()

const axiosMiddleware = ({getState, dispatch}) => next => action => {
    next(action)
    Object.keys(requestHandler).forEach(key => {
        const handler = requestHandler[key]
        if (!(handler && handler[action.type])) return
        const {
            method,
            data: unProcessedData,
            onSuccess,
            onFailure,
            headers,
            overrideTask,
            url: path,
            nonApiUrl,
            hasPathParameters
        } = handler[action.type](action.payload)

        let url = path, data = unProcessedData, pathParameters

        //pass data as query parameters on GET/DELETE requests
        const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data'

        // remove possible path parameters passed by calling components, path parameters:
        // 1) should be defined as an array at the request definition (api.js file, hasPathParameters property)
        // 2) be removed from data to differentiate from query parameters
        if (hasPathParameters && hasPathParameters.length > 0) {
            if (unProcessedData instanceof FormData) {
                // @ts-ignore
                const jsonData = Object.fromEntries(unProcessedData)
                pathParameters = _.pick(jsonData, hasPathParameters)
            } else {
                data = _.omit(unProcessedData, hasPathParameters)
                pathParameters = _.pick(unProcessedData, hasPathParameters)
            }
            url = getRouteUrl(
                {
                    component: 'div',
                    key: '',
                    name: '',
                    routeOptions: {},
                    path: path
                },
                pathParameters
            )
        }
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        // If we need to use non cookie authentication,
        // also remove withCredentials: true
        const state = getState();
        const LANG = state.currentUser.get('language');
        const accessToken = state.currentUser.get('token');
        if (accessToken) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
        }
        let request
        if (overrideTask) {
            request = overrideTask(unProcessedData, {parameters: action.parameters, dispatch});
        } else {
            const instance = axios
                .create({
                    // withCredentials: true,
                })
            request = instance.request({
                url: nonApiUrl ? url : API + '/' + LANG + url,
                method,
                headers,
                [dataOrParams]: data,
            })
        }
        //submit the request, then based on result handle response
        //also we are returning the initial data object if needed
        request
            .then((response) => {
                if (onSuccess) {
                    dispatch(onSuccess(response.data, unProcessedData))
                }
            })
            .catch(error => {
                if (onFailure) {
                    let {message, response} = error
                    if (
                        action.type !== 'USER_LOGIN' &&
                        response && response.status === 401 && response.statusText === "Unauthorized"
                    ) {
                        dispatch(userLogout())
                        return
                    }
                    const hasData = response && response.data
                    if (hasData && response.data.errors) {
                        message = response.data.errors
                    }
                    else if (hasData && response.data.error) {
                        message = response.data.error
                    }
                    else if (hasData && response.data.message) {
                        message = response.data.message
                    }
                    dispatch(onFailure({message: message}, unProcessedData))
                }
            })
    })
}

export default axiosMiddleware
// @flow
import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../locales/components/namespaces";
import TableContainer from "@mui/material/TableContainer";
import {Chip, Paper, TableCell} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import HighlightedString from "../highlightedString/highlightedString";
import TextField from '@mui/material/TextField';
import {normalizeGreek} from "../../helpers/normalizeGreek";
import {useFormikContext} from "formik";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
    autoFocus?: boolean,
    id?: string,
    name?: string,
    onBlur?: Function,
    onChange?: Function,
    placeholder?: string,
    value?: string,
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <span>-</span>;
        }
        return this.props.children;
    }
}

const Highlight = ({f, option, field, query}) => {
    return field.id === f ? (
        <HighlightedString
            text={option[f] ?? ""}
            query={query}
        />
    ) : option[f]
}

let debounce

const SearchInput = (props: Props) => {
    const {
        searchFieldRef,
        onChange,
        textfieldProps,
        searchAfterNoOfCharacters,
        handleOpen,
        enableSearch,
        field,
        onClick,
        loading,
        handleFocus,
        onKeyUp
    } = props;
    const {setFieldValue} = useFormikContext()

    const onSearchChange = () => {
        clearTimeout(debounce)
        searchFieldRef.current.value = (field.type !== 'email' && field.type !== 'companyWebsite' && field.type !== 'facebook' && field.type !== 'instagram') ? normalizeGreek(searchFieldRef.current.value.toUpperCase()) : searchFieldRef.current.value
        setFieldValue("fieldValues." + field.id, searchFieldRef.current.value)
        debounce = setTimeout(() => {
            const refValue = searchFieldRef.current.value
            if (enableSearch && refValue.length >= searchAfterNoOfCharacters) {
                onChange(refValue)
                handleOpen(true)
            }
        }, 1000)
    }

    return (
        <div
            style={{
                position: 'relative'
            }}
        >
            <TextField
                type={field.inputType}
                inputRef={searchFieldRef}
                {...textfieldProps}
                onChange={onSearchChange}
                onClick={onClick}
                InputLabelProps={{shrink: true}}
                onFocus={handleFocus}
                defaultValue={field.id === 'mobilePhone' ? '30' : ''}
                onKeyUp={onKeyUp}
            />
            {loading && (<CircularProgress
                color="inherit"
                size={16}
                sx={{
                    position: 'absolute',
                    zIndex: '1',
                    right: '8px',
                    bottom: '14px'
                }}
            />)}
        </div>
    )
}

const Options = (props: Props) => {
    const {
        options,
        searchFieldMappings,
        defaultPreregistrationFields,
        field,
        onSelect,
        open,
        handleOpen,
        searchFieldRef,
        isFocused
    } = props

    const {t} = useTranslation(VISITOR_PROFILE)

    return open && isFocused && options.length > 0 ? (
        <TableContainer component={Paper} sx={{
            position: 'absolute',
            zIndex: 100,
            maxHeight: '450px',
        }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {Object
                            .keys(searchFieldMappings)
                            .map(k => {
                                const definition = defaultPreregistrationFields.find(f => f.id === k)
                                return (
                                    <TableCell key={k}>
                                        {definition && definition.name ? t(definition.name) : k}
                                    </TableCell>
                                )
                            })}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {options.map((option) => {
                            return (
                                <TableRow
                                    key={option.id}
                                    sx={{display: "table-row!important"}}
                                    onClick={(evt) => onSelect(evt, option, handleOpen, field.id)}
                                >
                                    {Object
                                        .keys(searchFieldMappings)
                                        .map(f => (
                                            <TableCell key={f}>
                                                <ErrorBoundary>
                                                    <Highlight
                                                        f={f}
                                                        option={option}
                                                        field={field}
                                                        query={searchFieldRef.current.value}
                                                    />
                                                </ErrorBoundary>
                                            </TableCell>
                                        ))}
                                    <TableCell sx={{
                                        '& .MuiChip-root': {
                                            height: 20,
                                            borderRadius: "2px",
                                        }
                                    }}>
                                        {option.existsInExhibition && (
                                            <Chip
                                                color={"success"}
                                                size={"small"}
                                                label={t('Visitor')}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    ) : null
}

const Autocomplete = (props: Props) => {
    const {
        searchFieldRef,
        onChange,
        options,
        display,
        searchFieldMappings,
        defaultPreregistrationFields,
        field,
        textfieldProps,
        loading,
        searchAfterNoOfCharacters,
        handleOptions,
        onVisitorSelect,
        interests,
        postalCodeFieldRef,
        enableSearch,
        values,
        onKeyUp
    } = props

    const node = useRef()
    const [open, setOpen] = useState(false)
    const handleOpen = (isOpen) => setOpen(isOpen)
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const handleOuterClick = (evt) => {
            if (node.current.contains(evt.target)) {
                return
            }
            setOpen(false)
        }

        const handleEscape = (evt) => {
            if (evt.key === 'Escape' || evt.key === 'Tab') {
                setOpen(false)
                setIsFocused(false)
            }
        }

        document.addEventListener('mousedown', handleOuterClick)
        document.addEventListener('keydown', handleEscape, false)
        return () => {
            document.removeEventListener('mousedown', handleOuterClick)
            document.removeEventListener('keydown', handleEscape, false)
        }
    }, [])

    const onClick = (evt) => {
        if (options.length > 0 && !values.id) {
            setOpen(true)
        }
    }

    const onSelect = (evt, option, handleOpen, selectedField) => {
        handleOpen(false)
        onVisitorSelect(option)
        handleOptions([])
    }

    const handleFocus = () => setIsFocused(true)

    return (
        <div ref={node} style={{
            position: 'relative'
        }}>
            <SearchInput
                loading={loading}
                enableSearch={enableSearch}
                field={field}
                searchFieldRef={searchFieldRef}
                onChange={onChange}
                textfieldProps={textfieldProps}
                searchAfterNoOfCharacters={searchAfterNoOfCharacters}
                handleOpen={handleOpen}
                onClick={onClick}
                handleFocus={handleFocus}
                onKeyUp={onKeyUp}
            />
            <Options
                options={options}
                searchFieldRef={searchFieldRef}
                display={display}
                searchFieldMappings={searchFieldMappings}
                defaultPreregistrationFields={defaultPreregistrationFields}
                field={field}
                onSelect={onSelect}
                open={open}
                handleOpen={handleOpen}
                loading={loading}
                isFocused={isFocused}
            />
        </div>
    )
}

export default Autocomplete
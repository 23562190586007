import * as React from 'react';
import {useContext} from 'react';
import {RegistrationContext} from "../../form/form";
import {useSearchVisitor} from "../../../hooks/registration/useSearchVisitor";
import Autocomplete from "../../autocomplete/autocomplete";
import {defaultPreregistrationFields} from "../../customizeRegistration/defaultFields";
import {searchFieldMappings} from "../../../helpers/helpers";
import MobilePhone from "./mobilePhone";
import TaxisSearch from "./taxisSearch";

const SearchVisitor = (props) => {
	const {
		searchFieldRef,
		formik,
		values,
		field,
		textfieldProps
	} = props
	const {
		interests,
		postalCodeFieldRef,
		phoneCodes,
		onPhoneCodeChange,
		defaultCountry,
		defaultPhoneCode
	} = useContext(RegistrationContext)
	const {loading, options, fetchVisitors, onVisitorSelect, handleOptions} = useSearchVisitor(field.id)
	const enableSearch = !formik.values.fieldValues.id
	return field.id === 'mobilePhone' ? (
		<MobilePhone
			defaultCountry={defaultCountry}
			defaultPhoneCode={defaultPhoneCode}
			searchFieldRef={searchFieldRef}
			fetchVisitors={fetchVisitors}
			display={'table'}
			options={options}
			searchFieldMappings={searchFieldMappings}
			defaultPreregistrationFields={defaultPreregistrationFields}
			field={field}
			textfieldProps={textfieldProps}
			loading={loading}
			searchAfterNoOfCharacters={3}
			handleOptions={handleOptions}
			onVisitorSelect={onVisitorSelect}
			interests={interests}
			postalCodeFieldRef={postalCodeFieldRef}
			enableSearch={enableSearch}
			values={values}
			phoneCodes={phoneCodes}
			onPhoneCodeChange={onPhoneCodeChange}
			formik={formik}
		/>
	) : (
		<>
			{field.id === 'vatNumber' && field.enableTaxisSearchRegistration ? (
				<TaxisSearch
					searchFieldRef={searchFieldRef}
					onChange={fetchVisitors}
					options={options}
					field={field}
					textfieldProps={textfieldProps}
					loading={loading}
					searchAfterNoOfCharacters={3}
					handleOptions={handleOptions}
					onVisitorSelect={onVisitorSelect}
					interests={interests}
					postalCodeFieldRef={postalCodeFieldRef}
					enableSearch={enableSearch}
					values={values}
					formik={formik}
				/>
			) : (
				<Autocomplete
					searchFieldRef={searchFieldRef}
					onChange={fetchVisitors}
					display={'table'}
					options={options}
					searchFieldMappings={searchFieldMappings}
					defaultPreregistrationFields={defaultPreregistrationFields}
					field={field}
					textfieldProps={textfieldProps}
					loading={loading}
					searchAfterNoOfCharacters={3}
					handleOptions={handleOptions}
					onVisitorSelect={onVisitorSelect}
					interests={interests}
					postalCodeFieldRef={postalCodeFieldRef}
					enableSearch={enableSearch}
					values={values}
				/>
			)}
		</>
	
	)
}

export default SearchVisitor

// @flow
import * as React from 'react';
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_REGISTRATION, ROUTE_PAGE_SCAN_BARCODE} from "../../routers/routes";
import {normalizeGreek} from "../../helpers/normalizeGreek";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";

const HomeGrid = () => {
    const {t} = useTranslation(EXHIBITION)
    return (
        <Box
            sx={{
                height: {
                    lg: '90vh',
                    md: '100%'
                }
            }}
        >
            <Grid
                container
                sx={{
                    height: 'calc(100% - 64px)',
                    paddingTop: 0
                }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        component={'div'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-evenly'
                        }}
                    >
                        <Button
                            sx={{
                                width: 460,
                                height: 460,
                                backgroundColor: '#ba000d',
                                '&:hover': {
                                    backgroundColor: '#ff7961',
                                    opacity: [0.9, 0.8, 0.7],
                                    color: '#000000'
                                },
                                "& .MuiButton-startIcon": { marginRight: "0px" },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#FFFFFF',
                                fontSize: '20px',
                                marginTop: '4px'
                            }}
                            startIcon={<QrCodeScannerIcon/>}
                            variant="contained"
                            component={Link}
                            to={getRouteUrl(ROUTE_PAGE_SCAN_BARCODE)}
                        >
                            {normalizeGreek(t('Scanner'))}
                        </Button>
                        <Button
                            sx={{
                                width: 460,
                                height: 460,
                                backgroundColor: 'primary.dark',
                                '&:hover': {
                                    backgroundColor: 'primary.light',
                                    opacity: [0.9, 0.8, 0.7],
                                    color: '#000000'
                                },
                                "& .MuiButton-startIcon": { marginRight: "0px" },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#FFFFFF',
                                fontSize: '20px',
                                marginTop: '4px'
                            }}
                            startIcon={<HowToRegIcon/>}
                            variant="contained"
                            component={Link}
                            to={getRouteUrl(ROUTE_PAGE_REGISTRATION)}
                        >
                            {normalizeGreek(t('Registration'))}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HomeGrid
// @flow
import * as React from 'react';
import Box from "@mui/material/Box";
import RenderField from "../renderField/renderField";

type Props = {};

const RenderFieldArray = (props: Props) => {
    const {fields, autoMargins = true, formik, values, handleBlur, handleChange, touched, errors} = props
    return (
        fields
            .map(f => (
                <Box
                    sx={{mb: autoMargins ? 0 : 1}}
                    key={f.id}
                >
                    <RenderField
                        formik={formik}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        fields={fields}
                        field={f}
                    />
                </Box>
            ))
    )
};

export default RenderFieldArray
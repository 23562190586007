import React from 'react';
import {normalizeGreek} from "../../helpers/normalizeGreek";
import Toolbar from "@mui/material/Toolbar";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox, Stack} from "@mui/material";
import {EXHIBITION} from "../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from "@mui/lab/LoadingButton";
import RestoreIcon from '@mui/icons-material/Restore';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from "@mui/material/Tooltip";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_VIEW_SCREEN} from "../../routers/routes";
import {Link} from "react-router-dom";
import {Compare} from "@mui/icons-material";

type Props = {
    clearFormAfterSubmit: boolean,
    handleClearFormAfterSubmit: Function,
    sendMessages: boolean,
    handleSendMessages: Function,
    formik: Object
};

const FormToolbar = ({loading, clearFormAfterSubmit, handleClearFormAfterSubmit, handleSendMessages, formik, sendMessages, resetForm, submitForm, onFetchLastVisitor, existingVisitorToNewEntry, handleInfoPopOver,  disableRegistrationMessages}: Props) => {

    const {t} = useTranslation(EXHIBITION)
    return (
        <Toolbar disableGutters variant={"dense"}>
            <Stack
                direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row'
                }}
                spacing={'10px'}
                sx={{flexWrap: 'wrap', width: '100%'}}
            >
                <LoadingButton
                    size={"small"}
                    startIcon={<FactCheckIcon/>}
                    variant="contained"
                    color="success"
                    onClick={(evt) => submitForm(evt, true)}
                    sx={{minHeight: '40px', marginRight: 'auto', mb: '4px'}}
                    loading={loading}
                    loadingPosition="start"
                >
                    {normalizeGreek(t('Check In'))}
                </LoadingButton>
                <FormControlLabel
                    value="start"
                    control={
                        <Checkbox
                            checked={clearFormAfterSubmit}
                            onChange={handleClearFormAfterSubmit}
                            size={'medium'}
                        />
                    }
                    label={t('Clear Form After Submit')}
                    labelPlacement="start"
                />
                <FormControlLabel
                    value="start"
                    control={
                        <Checkbox
                            checked={sendMessages}
                            onChange={handleSendMessages}
                            size={'medium'}
                            disabled={disableRegistrationMessages}
                        />
                    }
                    label={t('Send SMS/Email')}
                    labelPlacement="start"
                />
                <LoadingButton
                    size={"small"}
                    startIcon={<RestoreIcon/>}
                    variant="contained"
                    color="step4"
                    onClick={onFetchLastVisitor}
                    sx={{minHeight: '40px', color: '#FFFFFF', backgroundColor: '#ba68c8', mb: '4px'}}
                    loading={loading}
                    loadingPosition="start"
                >
                    {normalizeGreek(t('Restore'))}
                </LoadingButton>
                <LoadingButton
                    size={"small"}
                    startIcon={<ContentCopyIcon/>}
                    variant="contained"
                    color="info"
                    onClick={existingVisitorToNewEntry}
                    sx={{minHeight: '40px', mb: '4px'}}
                    loading={loading}
                    loadingPosition="start"
                >
                    {normalizeGreek(t('Copy'))}
                </LoadingButton>
                <LoadingButton
                    size={"small"}
                    startIcon={<ClearIcon/>}
                    variant="contained"
                    color="warning"
                    onClick={resetForm}
                    sx={{minHeight: '40px', mb: '4px'}}
                    loading={loading}
                    loadingPosition="start"
                    form={"preregistration"}
                >
                    {normalizeGreek(t('Clear'))}
                </LoadingButton>
                <LoadingButton
                    size={"small"}
                    startIcon={<InfoIcon/>}
                    variant="contained"
                    color="warning"
                    onClick={(evt) => handleInfoPopOver({
                        anchorEl: evt.currentTarget,
                        open: true
                    })}
                    sx={{
                        minHeight: '40px',
                        backgroundColor: '#212121',
                        "& .MuiButton-startIcon": {
                            margin: 0
                        },
                        mb: '4px'
                }}
                    loading={loading}
                    loadingPosition="start"
                />
                <Tooltip
                    title={t('Click this button to open a new browser tab and drag the window into another monitor')}>
                    <LoadingButton
                        size={"small"}
                        startIcon={<Compare/>}
                        component={Link}
                        to={getRouteUrl(ROUTE_PAGE_VIEW_SCREEN)}
                        target={"_blank"}
                        variant="contained"
                        loading={loading}
                        loadingPosition="start"
                        sx={{minHeight: '40px', mb: '4px'}}
                    >
                        {normalizeGreek(t("User data confirmation screen"))}
                    </LoadingButton>
                </Tooltip>
            </Stack>
        </Toolbar>
    );
};

export default FormToolbar
import {useContext, useState} from "react";
import axios from "axios";
import {API} from "../../helpers/constants";
import useUserData from "../user/useUserData";
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../locales/components/namespaces";
import {RegistrationContext} from "../../components/form/form";
import {handleAxiosErrors} from "../../helpers/errorHandling";

const useTaxisSearch = (formik) => {
	const {token} = useUserData()
	const {
		companyNameFieldRef,
		companyAddressFieldRef,
		cityFieldRef,
		postalCodeFieldRef
	} = useContext(RegistrationContext)
	const {t} = useTranslation(VISITOR_PROFILE)
	const [loading, setLoading] = useState(false)
	
	const taxisSearch = (taxRegNo) => {
		if (taxRegNo.length === 9) {
			setLoading(true)
			axios({
				url: API + '/en/visitor/search-taxis/' + taxRegNo,
				method: 'get',
				headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			}).then((response) => {
				setLoading(false)
				const taxisData = response?.data?.data
				if (taxisData && taxisData?.onomasia) {
					if (companyNameFieldRef.current && taxisData?.onomasia) {
						companyNameFieldRef.current.value = taxisData?.onomasia
						formik.setFieldValue('fieldValues.companyName', taxisData?.onomasia)
					}
					if (companyAddressFieldRef.current && taxisData?.postaddr) {
						let address = taxisData?.postaddr
						address = taxisData?.postaddrno ? address + ', ' + taxisData?.postaddrno : address
						companyAddressFieldRef.current.value = address
						formik.setFieldValue('fieldValues.companyAddress', address)
					}
					if (cityFieldRef.current && taxisData?.postarea) {
						cityFieldRef.current.value = taxisData?.postarea
						formik.setFieldValue('fieldValues.city', taxisData?.postarea)
					}
					if (postalCodeFieldRef.current && taxisData?.postzipcode) {
						postalCodeFieldRef.current.value = taxisData?.postzipcode
						formik.setFieldValue('fieldValues.postalCode', taxisData?.postzipcode)
					}
				} else {
					enqueueSnackbar(t('Tax Reg. No. not found on TAXIS!'), {
						variant: 'warning',
						autoHideDuration: 2000,
					})
				}
			}).catch((error) => {
				setLoading(false)
				enqueueSnackbar(handleAxiosErrors(error, t('Failed to search Tax Reg. No. on TAXIS'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
			})
		} else {
			enqueueSnackbar(t('Insert exactly 9 characters to search registration number on TAXIS!'), {
				variant: 'warning',
				autoHideDuration: 2000,
			})
		}
	}
	
	return {
		taxisSearch,
		loading
	}
}

export default useTaxisSearch
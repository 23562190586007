// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/badge/actions'
import {useCallback} from "react";

const useBadgeAction = () => {
    const dispatch = useDispatch()


    const fastScanBadge = useCallback((exhibitionId, badgeCode) => {
        // debugger
        dispatch(actions.fastScanBadge({
            exhibitionId: exhibitionId,
            badgeCode: badgeCode
        }))
    }, [dispatch])

    const clearScannedBadge = useCallback(() => {
        dispatch(actions.clearScannedBadge())
    }, [dispatch])

    return {
        fastScanBadge,
        clearScannedBadge
    }
}

export default useBadgeAction
import {useEffect, useState} from "react"

function useLocalStorageSync(storageKey: string) {
    const [value, setValue] = useState("{}")
    const onStorageUpdate = (e) => {
        const {key, newValue} = e
        if (key === storageKey) {
            setValue(newValue)
        }
    }
    const handleChange = (value) => {
        const newValue = JSON.stringify(value)
        setValue(newValue)
        localStorage.setItem(storageKey, newValue)
    }
    useEffect(() => {
        setValue(localStorage.getItem(storageKey) || "{}")
        window.addEventListener("storage", onStorageUpdate)
        return () => {
            window.removeEventListener("storage", onStorageUpdate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {
        value: JSON.parse(value),
        handleChange
    }
}

export default useLocalStorageSync

// @flow
import React, {Fragment, useState} from 'react';
import * as yup from 'yup'
import {FormikProvider, useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {Alert} from "@mui/material";
import useQueryParams from "../../hooks/useQueryParams";
import {Redirect} from "react-router-dom";
import {normalizeGreek} from "../../helpers/normalizeGreek";

const Login = () => {
    const queryParams = useQueryParams()
    const registeredSuccessfully = queryParams.get('registeredSuccessfully')
    const {t} = useTranslation(PAGE_LOGIN)
    const [forgotPassword, setForgotPassword] = useState(false)
    const {loading, errorLogin} = useUserData()
    const {userLogin} = useUserAction()
    const onSubmit = (values) => {
        userLogin(values)
    }
    const validationSchema = yup.object({
        username: yup
            .string()
            .required(t('required')),
        password: yup
            .string()
            .required(t('required')),
    })
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            rememberMe: false,
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    return (
        <Box sx={{mt: 8}}>
            {!registeredSuccessfully && <Redirect to="/"/>}
            <FormikProvider value={formik}>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
                    {registeredSuccessfully && (
                        <Alert severity="success" sx={{mb: 2, width: '100%'}}>
                            {t('Your pre-registration was successful. You will receive an email and an SMS with the details for your participation.')}
                        </Alert>
                    )}
                    {!forgotPassword && !registeredSuccessfully && (
                        <Fragment>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Username"
                                id={'username'}
                                name={'username'}
                                autoComplete={'username'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.username}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Password"
                                autoComplete="current-password"
                                type={'password'}
                                id={'password'}
                                name={'password'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                {normalizeGreek(t('Sign In'))}
                            </Button>
                            {errorLogin && <div><strong>{t(errorLogin)}</strong></div>}
                            {loading && <div>{t('loading')}...</div>}
                        </Fragment>
                    )}
                </Box>
            </FormikProvider>
        </Box>
    )
}

export default Login
// @flow
import * as React from 'react';
import {useMemo} from 'react';
import {listToTree} from "../../helpers/listToTree";
import Box from "@mui/material/Box";
import RenderFieldGroups from "../renderFieldGroups/renderFieldGroups";

type Props = {
	formik: Object,
	fields: Array,
	values: Object,
	handleBlur: Function,
	handleChange: Function,
	touched: Object,
	errors: Object
};

const Fields = ({
					formik,
					fields,
					values,
					handleBlur,
					handleChange,
					touched,
					errors,
				}: Props) => {
	
	const listFields = useMemo(() => {
			const tree = listToTree({items: fields})
			return tree.length > 0 ? tree : fields.map(item => {
				return ({
					id: item.id,
					parent: null,
					depth: 0,
					data: item,
					hasChildren: false,
					children: []
				})
			})
		},
		[fields]
	)
	
	return (
		<Box
			sx={{
				'& .Mui-disabled': {
					color: 'text.primary',
					WebkitTextFillColor: 'initial!important',
				},
				'& .MuiInputLabel-root.Mui-disabled': {
					color: 'text.primary',
					WebkitTextFillColor: 'initial!important',
				},
				textAlign: 'left'
			}}>
			<RenderFieldGroups
				formik={formik}
				values={values}
				handleBlur={handleBlur}
				handleChange={handleChange}
				touched={touched}
				errors={errors}
				fields={listFields}
			/>
		</Box>
	);
};

export default Fields

export function downloadFile(href, filename) {
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
    }, 0);
}

export function printFile(href) {
    const iframe = document.createElement('iframe')
    document.body.appendChild(iframe);
    iframe.style.display = 'none'
    iframe.src = href
    iframe.onload = function () {
        setTimeout(function () {
            iframe.focus()
            iframe.contentWindow.print()
        }, 1);
    }
}
import {withLanguage} from "../../helpers/parse";
import type {Countries} from "./type";

export const parseCountries: Countries = (datum, language) => {
    if(!datum) return
    return {
        id: datum.get('id'),
        description: withLanguage(language)(datum.get('description')),
        countryCode: datum.get('countryCode'),
        phoneCode: datum.get('phoneCode'),
        image: datum.get('image'),
    }
}
import {useEffect} from "react";
import {useInterestsAction} from "./useInterestsAction";
import {useInterestsData} from "./useInterestsData";

export const useInterests = (id) => {
    const {getInterests} = useInterestsAction(id)
    const {loading, sortedInterests: interests} = useInterestsData()
    useEffect(() => {
        getInterests(id)
    }, [getInterests, id])
    return {
        interests,
        loading
    }
}
// @flow
import React from 'react';
import Autocomplete from "../../autocomplete/autocomplete";
import {defaultPreregistrationFields} from "../../customizeRegistration/defaultFields";
import {findCountryCode, searchFieldMappings} from "../../../helpers/helpers";
import {Checkbox, FormControl, FormHelperText, InputLabel, OutlinedInput, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {ErrorMessage} from "formik";
import CountryPhoneCodeSelect from "./countryPhoneCodeSelect";

type Props = {};

const MobilePhone = (props: Props) => {
    const {
        defaultCountry,
        defaultPhoneCode,
        searchFieldRef,
        formik,
        values,
        field,
        handleBlur,
        handleChange,
        touched,
        errors,
        disabled,
        textfieldProps,
        value,
        interests,
        postalCodeFieldRef,
        loading,
        options,
        fetchVisitors,
        onVisitorSelect,
        handleOptions,
        enableSearch,
        phoneCodes,
        onPhoneCodeChange
    } = props
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
        }}
        >
            <div
                style={{
                    width: '500px',
                    paddingRight: '6px'
                }}
            >
                <CountryPhoneCodeSelect
                    phoneCodes={phoneCodes}
                    onPhoneCodeChange={onPhoneCodeChange}
                    defaultPhoneCode={defaultPhoneCode}
                    values={values}
                    formik={formik}
                />
            </div>
            <div
                style={{
                    width: '100%'
                }}
            >
                <Autocomplete
                    searchFieldRef={searchFieldRef}
                    onChange={fetchVisitors}
                    onKeyUp={(textValue) => {
                        let countryPhoneCode = findCountryCode(values.countryPhoneCode, values.mobilePhone, phoneCodes)
                        formik.setFieldValue('fieldValues.countryPhoneCode', countryPhoneCode)
                    }}
                    display={'table'}
                    options={options}
                    searchFieldMappings={searchFieldMappings}
                    defaultPreregistrationFields={defaultPreregistrationFields}
                    field={field}
                    textfieldProps={textfieldProps}
                    loading={loading}
                    searchAfterNoOfCharacters={3}
                    handleOptions={handleOptions}
                    onVisitorSelect={onVisitorSelect}
                    interests={interests}
                    postalCodeFieldRef={postalCodeFieldRef}
                    enableSearch={enableSearch}
                    values={values}
                />
            </div>
        </div>
    );
};

export default MobilePhone
import * as ActionTypes from '../../actions/types'
import {mapCountries} from "../../types/countries/map";
import {fromJS} from "immutable";

const INITIAL_STATE = {
    loading: false,
    byId: []
}

function getCountries(state) {
    return state
        .set('loading', true)
}

function getCountriesSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapCountries(payload)
    return state
        .set('loading', false)
        .set('byId', fromJS(mappedData))

}

function getCountriesFailed(state) {
    return state.set('loading', false)
}

const countries = {
    initialState: INITIAL_STATE, handlers: {
        [ActionTypes.GET_COUNTRIES]: getCountries,
        [ActionTypes.GET_COUNTRIES_SUCCEEDED]: getCountriesSucceeded,
        [ActionTypes.GET_COUNTRIES_FAILED]: getCountriesFailed
    } ,
}

export default countries
// @flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMessageStatus} from "../../types/badge/parse";
import type {MessageStatus} from "../../types/badge/types";

const selectScanBadgeStatus = createSelector(
    (state) => state.badge.get('scanBadgeStatus'),
    (item) => parseMessageStatus(item)
)

const useBadgeData = ({badgeId, exhibitionId} = {}) => {
    const scanBadgeStatus: MessageStatus = useSelector(selectScanBadgeStatus)
    return {
        scanBadgeStatus,
    }
}

export default useBadgeData
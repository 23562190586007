import {useCallback} from "react";
import {useDispatch} from "react-redux";
import * as actions from "../../actions/actions";

export const useInterestsAction = (id) => {
    const dispatch = useDispatch()
    const getInterests = useCallback( () => {
        dispatch(actions.getInterests({
            id: id
        }))
    }, [dispatch])

    return {
        getInterests
    }
}
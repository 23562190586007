// @flow
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {RegistrationContext} from "../../form/form";
import {FormControl, FormGroup, Checkbox as MuiCheckbox, FormHelperText,} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ErrorMessage, useFormikContext} from "formik";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import TextField from "@mui/material/TextField";

type Props = {};

const InterestsCheckBoxList = (props: Props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors, disabled} = props
    const {interests, otherInterestsFieldRef} = useContext(RegistrationContext)
    const [list, setList] = useState([])
    const {setFieldValue} = useFormikContext()
    const {t} = useTranslation(VISITOR_PROFILE)

    useEffect(() => {
        setList([...interests])
    }, [interests])

    const onChange = (evt, selection) => {
        let value = [...values.interests]
        const checked = evt.target.checked
        if (checked === true) {
            value.push(selection)
        } else {
            const getIndex = value.findIndex(f => f.id === selection.id)
            value.splice(getIndex, 1)
        }
        setFieldValue("fieldValues." + field.id, value)
    }

    return (
        <FormControl
            component="fieldset"
            size={"small"}
            fullWidth
            sx={{
                bgcolor: "background.paper",
                padding: 1,
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
            }}
            error={
                touched &&
                touched["fieldValues"] &&
                touched["fieldValues"][field.id] &&
                Boolean(errors["fieldValues"] && errors["fieldValues"][field.id])
            }
        >
            <Typography
                variant={"caption"}
                sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    top: '-11px',
                    position: 'absolute',
                    bgcolor: '#F5F5F5',
                    padding: '0 6px'
                }}
            >
                {t('Interests')}{field.required ? <sup>*</sup> : ''}
            </Typography>
            <FormGroup>
                <div style={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(4, auto)',
                    gridAutoFlow: 'column'
                }}>
                    {list.map(option => (
                        <FormControlLabel
                            key={option.id}
                            control={
                                <MuiCheckbox
                                    onChange={(evt) => onChange(evt, option)}
                                    checked={!!(value && value.find(f => f.id === option.id))}
                                />
                            }
                            label={option.description}
                        />
                    ))}
                </div>
                {field.addNew === true && (
                    <TextField
                        label={t('Other')}
                        size={"small"}
                        type={'text'}
                        name={'otherInterests'}
                        onChange={(evt) => setFieldValue("fieldValues.otherInterests", evt.target.value)}
                        fullWidth
                        autoComplete={"chrome-off"}
                        inputRef={otherInterestsFieldRef}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            </FormGroup>
            <FormHelperText>
                <ErrorMessage name={"fieldValues." + field.id}/>
            </FormHelperText>
        </FormControl>
    );
};

export default InterestsCheckBoxList
import useUserData from "../user/useUserData";
import useExhibitionData from "../exhibitions/useExhibitionData";
import useExhibitionAction from "../exhibitions/useExhibitionAction";
import {useContext, useEffect, useRef, useState} from "react";
import {usePreregistrationForm} from "./useRegistrationForm";
import type {PreregistrationFormValues} from "../../types/exhibition/types";
import axios from "axios";
import {API} from "../../helpers/constants";
import {mapVisitorDataToPreregistrationFormValues} from "../../types/exhibition/map";
import {TitleContext} from "../../pages/main/content";
import {getPdfBadgeUrl} from "../../components/printBadgeButton/printBadgeButton";
import {printFile} from "../../helpers/fileHandle";
import {useAuthenticatedFile} from "../../components/authenticatedLink/useAuthenticatedFile";
import {findCountryCode} from "../../helpers/helpers";

export const useForm = ({id, exhibition, countries, interests, phoneCodes, defaultCountry, defaultPhoneCode, categories}) => {
    const {token} = useUserData()
    const {loading, actionOnVisitorStatus} = useExhibitionData(id)
    const {createVisitor} = useExhibitionAction()

    const [isUpdate, setIsUpdate] = useState(false)
    const [sendMessages, setSendMessages] = useState(exhibition.disableRegistrationMessages !== true)
    const [clearFormAfterSubmit, setClearFormAfterSubmit] = useState(true)

    const companyNameFieldRef = useRef(null)
    const nameFieldRef = useRef(null)
    const vatFieldRef = useRef(null)
    const emailFieldRef = useRef(null)
    const mobilePhoneFieldRef = useRef(null)
    const phoneFieldRef = useRef(null)
    const companyAddressFieldRef = useRef(null)
    const cityFieldRef = useRef(null)
    const companyWebsiteFieldRef = useRef(null)
    const instagramFieldRef = useRef(null)
    const facebookFieldRef = useRef(null)
    const positionFieldRef = useRef(null)
    const personNumberFieldRef = useRef(null)
    const postalCodeFieldRef = useRef(null)
    const otherInterestsFieldRef = useRef(null)

    const {printSize: contextSize} = useContext(TitleContext)
    const {fetchFile} = useAuthenticatedFile()

    useEffect(() => {
        if (
            actionOnVisitorStatus &&
            actionOnVisitorStatus.type === "success" &&
            actionOnVisitorStatus.parameters &&
            actionOnVisitorStatus.parameters.id
        ) {
            const url = getPdfBadgeUrl("visitor", '', exhibition.visitorRegBadgeTemplateTypeId, actionOnVisitorStatus.parameters.id)
            fetchFile(url, printFile)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionOnVisitorStatus])

    const resetForm = () => {
        formik.resetForm()
        if (vatFieldRef.current?.value) {
            vatFieldRef.current.value = ""
        }
        if (companyNameFieldRef.current?.value) {
            companyNameFieldRef.current.value = ""
        }
        if (emailFieldRef.current?.value) {
            emailFieldRef.current.value = ""
        }
        if (mobilePhoneFieldRef.current?.value) {
            mobilePhoneFieldRef.current.value = 30
        }
        if (phoneFieldRef.current?.value) {
            phoneFieldRef.current.value = ""
        }
        if (companyAddressFieldRef.current?.value) {
            companyAddressFieldRef.current.value = ""
        }
        if (cityFieldRef.current?.value) {
            cityFieldRef.current.value = ""
        }
        if (companyWebsiteFieldRef.current?.value) {
            companyWebsiteFieldRef.current.value = ""
        }
        if (facebookFieldRef.current?.value) {
            facebookFieldRef.current.value = ""
        }
        if (instagramFieldRef.current?.value) {
            instagramFieldRef.current.value = ""
        }
        if (positionFieldRef.current?.value) {
            positionFieldRef.current.value = ""
        }
        if (personNumberFieldRef.current?.value) {
            personNumberFieldRef.current.value = 1
        }
        if (postalCodeFieldRef.current?.value) {
            postalCodeFieldRef.current.value = ""
        }
        if (otherInterestsFieldRef.current?.value) {
            otherInterestsFieldRef.current.value = ""
        }
        nameFieldRef.current.value = ""
    }

    const onSubmit = (values) => {
        const visitorValues = {
            ...values.fieldValues,
            name: nameFieldRef.current.value,
            companyName: companyNameFieldRef.current?.value,
            vatNumber: vatFieldRef.current?.value,
            mobilePhone: mobilePhoneFieldRef?.current.value,
            phone: phoneFieldRef.current?.value,
            email: emailFieldRef.current?.value,
            postalCode: postalCodeFieldRef.current?.value,
            otherInterests: otherInterestsFieldRef.current?.value
        }
        createVisitor(id, null, visitorValues, true, isUpdate, sendMessages)
        if(clearFormAfterSubmit) {
            resetForm()
        }
        setIsUpdate(false)
    }

    const {
        formik,
        fieldValues,
        fields
    } = usePreregistrationForm({
        exhibition: exhibition,
        onSubmit: onSubmit,
        useLocalStorage: true,
        isFromRegistration: true,
        countries: countries,
        defaultCountry: defaultCountry,
        defaultPhoneCode: defaultPhoneCode
    })

    const submitForm = (evt, update) => {
        setIsUpdate(update)
        formik.submitForm(evt)
    }

    const onPhoneCodeChange = (event, newValue) => {
        formik.setFieldValue('fieldValues.countryPhoneCode', newValue)
        if(newValue) {
            mobilePhoneFieldRef.current.value = newValue.phoneCode
        }
    }

    const onVisitorSelect = (values: PreregistrationFormValues) => {
        if (vatFieldRef.current) {
            vatFieldRef.current.value = values.vatNumber
        }
        if (companyNameFieldRef.current) {
            companyNameFieldRef.current.value = values.companyName
        }
        if (emailFieldRef.current) {
            emailFieldRef.current.value = values.email
        }
        if (mobilePhoneFieldRef.current) {
            mobilePhoneFieldRef.current.value =  values.mobilePhone
        }
        if (phoneFieldRef.current) {
            phoneFieldRef.current.value = values.phone
        }
        if (companyAddressFieldRef.current) {
            companyAddressFieldRef.current.value = values.companyAddress
        }
        if (cityFieldRef.current) {
            cityFieldRef.current.value = values.city
        }
        if (companyWebsiteFieldRef.current) {
            companyWebsiteFieldRef.current.value = values.companyWebsite
        }
        if (facebookFieldRef.current) {
            facebookFieldRef.current.value = values.facebook
        }
        if (instagramFieldRef.current) {
            instagramFieldRef.current.value = values.instagram
        }
        if (positionFieldRef.current) {
            positionFieldRef.current.value = values.position
        }
        if (personNumberFieldRef.current) {
            personNumberFieldRef.current.value = values.personCount
        }
        if (postalCodeFieldRef.current) {
            postalCodeFieldRef.current.value = values.postalCode
        }
        if (otherInterestsFieldRef.current) {
            otherInterestsFieldRef.current.value = values.otherInterests
        }
        nameFieldRef.current.value = values.name
        let countryPhoneCode = findCountryCode(values.countryPhoneCode, values.mobilePhone, phoneCodes)
        formik.setValues({
            ...formik.values,
            fieldValues: {
                ...values,
                countries: typeof values.countries === 'string' && values.countries ? countries.find(f => f.id === values.countries) : values.countries,
                characterization: typeof values.characterization === 'string' && values.characterization ? categories.find(f => f.id === values.characterization) : values.characterization,
                interests: interests.filter(f => values.interests.includes(f.id)),
                countryPhoneCode: countryPhoneCode
            },
        })
    }

    const onFetchLastVisitor = () => {
        axios({
            url: API + '/en/visitor/search-last',
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        }).then((response) => {
            const {data} = response
            if(Object.keys(data).length !== 0) {
                const mappedData = mapVisitorDataToPreregistrationFormValues(data)
                if(mappedData) {
                    onVisitorSelect(mappedData)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleSendMessages = (evt) => {
        setSendMessages(evt.target.checked)
    }

    const handleClearFormAfterSubmit = (evt) => {
        setClearFormAfterSubmit(evt.target.checked)
    }

    const existingVisitorToNewEntry = (evt) => {
        formik.setFieldValue('fieldValues.id', null)
    }

    return {
        loading,
        companyNameFieldRef,
        nameFieldRef,
        vatFieldRef,
        emailFieldRef,
        mobilePhoneFieldRef,
        phoneFieldRef,
        companyAddressFieldRef,
        cityFieldRef,
        companyWebsiteFieldRef,
        instagramFieldRef,
        facebookFieldRef,
        positionFieldRef,
        personNumberFieldRef,
        postalCodeFieldRef,
        otherInterestsFieldRef,
        formik,
        fields,
        fieldValues,
        resetForm,
        submitForm,
        onPhoneCodeChange,
        onVisitorSelect,
        onFetchLastVisitor,
        existingVisitorToNewEntry,
        clearFormAfterSubmit,
        handleClearFormAfterSubmit,
        sendMessages,
        handleSendMessages
    }
}
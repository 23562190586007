import type {
    Exhibition, PreregistrationFormValues,
    UnmappedExhibition, UnmappedPreregistrationFormValues, UnmappedVisitor, Visitor,
} from "./types";
import {defaultPreregistrationFields} from "../../components/customizeRegistration/defaultFields";
import moment from "moment";
import {mapInterests} from "../interests/map";
import {API, EXHIBITION_IMAGE_RELATIVE_PATH} from "../../helpers/constants";


export const mapExhibition: Exhibition = (datum: UnmappedExhibition) => {
    if (!datum) return
    const from = moment(datum.StartDt, 'YYYY-MM-DD').toDate()
    const to = moment(datum.EndDt, 'YYYY-MM-DD').toDate()
    const lastModifiedAt = moment(datum.LastModifiedAt, 'YYYY-MM-DD').toDate()
    const preregistrationFrom = datum.StartDtPreregister ? moment(datum.StartDtPreregister, 'YYYY-MM-DD').toDate() : null
    const preregistrationTo = datum.EndDtPreregister ? moment(datum.EndDtPreregister, 'YYYY-MM-DD').toDate() : null
    return {
        id: datum.id,
        erpId: datum.ErpId,
        erpCode: datum.ErpCode,
        dateFrom: from,
        dateTo: to,
        daysForCatalogSubmission: Number(datum.DaysForCatalogSubmission),
        typeId: datum.TypeId,
        categoryId: datum.CategId,
        familyId: datum.FamilyId,
        numberOfPartnersPerExhibitor: Number(datum.PartnerNum),
        preregistrationEnabled: datum.isPreregistration === "1",
        preregistrationFormFields: datum.PreRegForm ? JSON.parse(datum.PreRegForm) : defaultPreregistrationFields,
        lastModifiedBy: datum.LastModifiedBy,
        lastModifiedAt: lastModifiedAt,
        isRequireRegistration: datum.isRequireRegistration === '1',
        type: datum.Type,
        category: datum.Category ? datum.Category.split(",") : [],
        family: datum.Family,
        image: {
            el: datum.Logo ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.Logo : null,
            en: datum.LogoEN ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.LogoEN : null,
        },
        title: {
            en: datum.DescriptionEN ?? '',
            el: datum.DescriptionEL ?? '',
        },
        description: {
            en: datum.SummaryEN ?? '',
            el: datum.SummaryEL ?? '',
        },
        welcomeMessage: datum.WelcomeText ?? {
            en: datum.WelcomeTextEN ?? '',
            el: datum.WelcomeTextEL ?? '',
        },
        preregistrationFrom: preregistrationFrom,
        preregistrationTo: preregistrationTo,
        preregistrationNeedsApproval: datum.isApproveRegistration === "1",
        catalogLocked: datum.isProfileLocked === "1",
        catalogEnableKioskSign: datum.CatalogEnableKioskSign === "1",
        catalogueLanguage: Number(datum.CatalogueLanguage),
        displayVisitorsToExhibitor: datum.ExhibitorVisitors === "1",
        displayPartnersToExhibitor: datum.ExhibitorPartners === "1",
        displayServiceProvidersToExhibitor: datum.ExhibitorServiceProviders === '1',
        displayStaffToExhibitor: datum.ExhibitorStaff === '1',
        displayOrganizersToExhibitor: datum.ExhibitorOrganizers === '1',
        hasVisitors: datum.hasVisitors === '1',
        hasPartners: datum.hasPartners === '1',
        hasServiceProviders: datum.hasServiceProviders === '1',
        hasStaff: datum.hasStaff === '1',
        hasOrganizers: datum.hasOrganizers === '1',
        exhibitorInvitesVisitorsWithBarcode: datum.ExhibitorVisitorBarcode === "1",
        coorganizationId: datum.CoorganizationId,
        coorganizationDescription: {
            en: datum.CoorganizationEN ?? '',
            el: datum.CoorganizationEL ?? '',
        },
        visitorCategoryRequiredInImport: datum.VisitorCategoryRequired === "1",
        preregistrationRedirect: datum.PreregistrationRedirect,
        preregistrationHasFieldGroups: datum.PreregistrationHasFieldGroups === "1",
        domainPortal: datum.DomainPortal,
        domainPreregistration: datum.DomainPreregistration,
        domainRegistration: datum.DomainRegistration,
        disableRegistrationMessages: datum.DisableRegistrationMessages === '1',
        preregistrationCountry: datum.PreregistrationCountry,
        preregistrationApprovePerCountry: Number(datum.PreregistrationApprove),
        catalogueProfileLength: Number(datum.CatalogueProfileLength),
        hasExhibitorBrands: datum.hasExhibitorBrands === '1',
        visitorBadgeTemplateTypeId: datum.VisitorBadgeTemplateTypeId,
        visitorRegBadgeTemplateTypeId: datum.VisitorRegBadgeTemplateTypeId,
        partnerBadgeTemplateTypeId: datum.PartnerBadgeTemplateTypeId,
        providerBadgeTemplateTypeId: datum.ProviderBadgeTemplateTypeId,
        staffBadgeTemplateTypeId: datum.StaffBadgeTemplateTypeId,
        organizerBadgeTemplateTypeId: datum.OrganizerBadgeTemplateTypeId,
        sendFromEmail: datum.SendFromEmail,
        sendFromName: datum.SendFromName,
        isKioskSignEditable: datum.isKioskSignEditable === '1',
        registrationStartDays: Number(datum.RegistrationStartDays),
        registrationEndDays: Number(datum.RegistrationEndDays),
        validQrDays: Number(datum.ValidQrDays),
        quickPrintFooterImage: {
            el: datum.CharacterizationImgEL ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.CharacterizationImgEL : null,
            en: datum.CharacterizationImgEN ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.CharacterizationImgEN : null,
        },
        daysForCatalogNotification: Number(datum.DaysForCatalogNotification)
    }
}


export const mapVisitor: Visitor = (datum: UnmappedVisitor) => {
    if (!datum) return
    return {
        Badge: datum.Badge,
        Characterization: datum.Characterization,
        City: datum.address && datum.address.City ? datum.address.City : '',
        CompanyAddress: datum.address && datum.address.Address ? datum.address.Address : '',
        CompanyFirm: datum.CompanyFirm,
        CompanyName: datum.CompanyName,
        ContactId: datum.ContactId,
        CountryId: datum.CountryId,
        ExhibitionId: datum.ExhibitionId,
        InvitedBy: {
            id: datum.ExhibitorId,
            name: datum.ExhibitorName,
            totalInvitations: Number(datum.ExhibitorCount),
        },
        InvitationDt: moment(datum.InvitationDt).toDate(),
        PersonCount: datum.PersonCount,
        PostalCode: datum.address && datum.address.ZipCode ? datum.address.ZipCode : '',
        Status: datum.Status,
        StatusId: Number(datum.StatusId),
        VatNumber: datum.VatNumber,
        VisitorBusinessPhone: datum.VisitorBusinessPhone,
        VisitorEmail: datum.VisitorEmail,
        VisitorMobilePhone: datum.VisitorMobilePhone,
        VisitorName: datum.VisitorName,
        Website: datum.Website,
        Facebook: datum.Facebook,
        Instagram: datum.Instagram,
        // businessCategories: mapBusinessCategories(datum.businessCategories),
        existsInExhibition: datum.existsInExhibition,
        hasRevisited: datum.hasRevisited,
        id: datum.id,
        interests: mapInterests(datum.interests),
        isApproveRegistration: datum.isApproveRegistration,
        isIndividual: datum.isIndividual,
        organizerApprovalRejectReason: datum.organizerApprovalRejectReason
    }
}

export const mapVisitorDataToPreregistrationFormValues: PreregistrationFormValues = (datum: UnmappedPreregistrationFormValues) => {
    if (!datum) return
    return {
        companyAddress: datum.Address ?? '',
        characterization: datum.CharacterizationId ?? '',
        city: datum.City ?? '',
        companyFirm: datum.CompanyFirm ?? '',
        companyName: datum.CompanyName ?? '',
        id: datum.ContactId,
        countries: datum.CountryId,
        interests: datum.Interests ? datum.Interests.split(',').filter( i => i !== '' ) : [],
        otherInterests: datum.OtherInterests,
        personCount: datum.PersonCount ?? 1,
        position: datum.Position ?? 1,
        vatNumber: datum.VatNumber ?? '',
        phone: datum.VisitorBusinessPhone ?? '',
        email: datum.VisitorEmail ?? '',
        mobilePhone: datum.VisitorMobilePhone ?? '',
        name: datum.VisitorName ?? '',
        companyWebsite: datum.WebSite ?? '',
        instagram: datum.Instagram ?? '',
        facebook: datum.Facebook ?? '',
        postalCode: datum.ZipCode ?? '',
        existsInExhibition: datum.existsInExhibition === "1",
        hasRevisited: datum.hasRevisited === "1",
        isIndividual: datum.isIndividual === "1"
    }
}

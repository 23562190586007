import Home from "../pages/home/home";
import {routeCreator} from "../helpers/routeCreator";
import NotFound from "../pages/notFound/notFound";
import Login from "../pages/login/login";
import ScanBarcode from "../pages/scanBarcode/scanBarcode";
import Registration from "../pages/registration/registration";
import ViewScreen from "../pages/viewScreen/viewScreen";

export const ROUTE_PAGE_LOGIN = routeCreator(Login, '/', 'login', 'Login', 'page.login')
export const ROUTE_PAGE_HOME = routeCreator(Home, '/', 'Home', 'page.home', {exact: true})
export const ROUTE_PAGE_REGISTRATION = routeCreator(Registration, '/registration', 'Registration', 'page.registration',{exact: true})
export const ROUTE_PAGE_VIEW_SCREEN = routeCreator(ViewScreen, '/view/screen', 'View Screen', 'page.view.screen', {exact: true})
export const ROUTE_PAGE_SCAN_BARCODE = routeCreator(ScanBarcode, '/scan/barcode', 'Scan Barcode', 'page.scan.barcode',{exact: true})
export const ROUTE_PAGE_NOT_FOUND = routeCreator(NotFound, '/', 'Not Found', 'page.notFound')

const router = {
    ROUTE_PAGE_HOME,
    ROUTE_PAGE_REGISTRATION,
    ROUTE_PAGE_VIEW_SCREEN,
    ROUTE_PAGE_SCAN_BARCODE,
    ROUTE_PAGE_NOT_FOUND
}

export default router;
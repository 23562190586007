//@flow
import React, {useEffect, useState} from 'react';
import {Alert, Fade, Snackbar} from "@mui/material";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../locales/components/namespaces";
import useBadgeAction from "../../hooks/badges/useBadgeAction";
import useBadgeData from "../../hooks/badges/useBadgeData";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DebounceInput from "react-debounce-input";
import {QrReader} from "react-qr-reader";
import Button from "@mui/material/Button";
import SelectFromOngoingExhibition from "../../components/selectFromOngoingExhibition/selectFromOngoingExhibition";
import InfoPopOver from "../../components/infoPopOver/infoPopOver";
import ScanInfo from "../../components/scanInfo/scanInfo";
import InfoIcon from "@mui/icons-material/Info";
import {Clear} from "@mui/icons-material";

const ScanBarcodeInner = ({selectedExhibition}) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {fastScanBadge, clearScannedBadge} = useBadgeAction()
    const {scanBadgeStatus} = useBadgeData()
    const [scannedText, setScannedText] = useState(null)
    const [lastScannedText, setLastScannedText] = useState(null)
    const [infoPopOver, setInfoPopOver] = useState({
        anchorEl: null,
        open: false,
        body: ''
    })
    const handleInfoPopOver = (value) => {
        setInfoPopOver({...value})
    }
    useEffect(() => {
        return () => {
            clearScannedBadge()
        }
    }, [clearScannedBadge])
    useEffect(() => {
        if (scannedText && scannedText.trim() !== "" && scannedText !== lastScannedText) {
            fastScanBadge(selectedExhibition, scannedText)
            setLastScannedText(scannedText)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scannedText, selectedExhibition])
    useEffect(() => {
        if (scanBadgeStatus && scanBadgeStatus.time) {
            setScannedText("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanBadgeStatus?.time])

    return (
        <Box
            sx={{
                'WebkitUserSelect': 'none', // Safari
                'msUserSelect': 'none', // IE 10 and IE 11
                'userSelect': 'none' // Standard syntax
            }}
        >
            <Box
                sx={{
                    textAlign: 'center',
                    maxWidth: 800,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 'auto',
                    width: '100%',
                    paddingTop: '5px'
                }}
            >
                <Typography variant={"h3"}>
                    {t('Scan Badge')}...
                </Typography>
                <Typography variant={"h6"} style={{color: 'orange'}}>
                    {t('Attention! Cursor must be placed inside the field to scan the barcode successfully...')}
                    <Button
                        size={"small"}
                        startIcon={<InfoIcon/>}
                        variant="contained"
                        color="success"
                        onClick={(evt) => handleInfoPopOver({
                            anchorEl: evt.currentTarget,
                            open: true
                        })}
                        sx={{
                            "& .MuiButton-startIcon": {
                                margin: 0,
                                p: 0,
                            },
                            width: '0px',
                            pl: 0,
                            pr: 0,
                            minWidth: '30px',
                            ml: 1
                        }}
                    />
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    maxWidth: 800,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '10px',
                    marginBottom: '5px',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        position: 'relative'
                    }}
                >
                    <DebounceInput
                        value={scannedText}
                        debounceTimeout={800}
                        onChange={evt => {
                            setScannedText(evt.target.value)
                        }}
                        autoFocus
                        style={{
                            borderRadius: '8px',
                            minHeight: '50px',
                            width: '100%',
                            marginRight: '4px'
                        }}
                    />
                    <Button
                        size={"small"}
                        startIcon={<Clear/>}
                        variant="contained"
                        color="warning"
                        onClick={(evt) => {
                            setScannedText('')
                            setLastScannedText('')
                            clearScannedBadge()
                        }}
                        sx={{
                            "& .MuiButton-startIcon": {
                                margin: 0,
                                p: 0,
                            },
                            width: '0px',
                            pl: 0,
                            pr: 0,
                            minWidth: '30px',
                            ml: 1,
                            position: 'absolute',
                            right: 8,
                            backgroundColor: '#212121',
                        }}
                    />
                </Box>
                <QrReader
                    onResult={(result, error) => {
                        if (!!result) {
                            setScannedText(result?.text)
                        }
                        if (!!error) {
                            // console.info(error);
                        }
                    }}
                    videoContainerStyle={{
                        width: '360px',
                        height: '300px',
                        marginTop: '6px',
                        position: 'relative'
                    }}
                    constraints={{facingMode: 'environment'}}
                    scanDelay={800}
                />
            </Box>
            <Snackbar
                open={Boolean(scanBadgeStatus)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                autoHideDuration={14000}
                TransitionComponent={Fade}
            >
                <Alert
                    severity={scanBadgeStatus?.type}
                    sx={{
                        opacity: scanBadgeStatus ? 1 : 0,
                        maxWidth: 800,
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        marginBottom: 'auto',
                        textAlign: 'center',
                        borderRadius: '8px',
                        width: "100%",
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="h4" gutterBottom component="div" sx={{width: '100%'}}>
                        <Box sx={{width: '100%'}}>
                            <strong>{t(scanBadgeStatus?.message)}</strong>
                        </Box>
                        <Box sx={{width: '100%'}}>
                            <strong>{scanBadgeStatus?.type !== 'success' ? scanBadgeStatus?.name : ''}</strong>
                        </Box>
                    </Typography>
                </Alert>
            </Snackbar>
            <InfoPopOver
                infoPopOver={infoPopOver}
                handleInfoPopOver={handleInfoPopOver}
                component={<ScanInfo/>}
            />
        </Box>
    )
}

const ScanBarcode = () => {
    const [selectedExhibition, setSelectedExhibition] = useState(null)
    return (
        <>
            <SelectFromOngoingExhibition
                value={selectedExhibition}
                onSubmit={setSelectedExhibition}
            />
            {selectedExhibition && (
            <ScanBarcodeInner
                selectedExhibition={selectedExhibition}
            />
            )}
        </>
    )
}

export default ScanBarcode

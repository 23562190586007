import * as ActionTypes from '../actions/types'
import { fromJS } from 'immutable'
import { mapPermissions } from '../types/map'

const INITIAL_STATE = {
  loading: false,
  currentUser: []
}

function getCurrentUserPermissions (state) {
  return state
    .set('loading', true)
}

function getCurrentUserPermissionsSucceeded (state, action) {
  const { portalRights } = action.payload
  return state
    .set('loading', false)
    .set('currentUser', fromJS(mapPermissions(portalRights)))
}

function getCurrentUserPermissionsFailed (state, action) {
  return state
    .set('loading', false)
}

const reducer = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.GET_USER_PERMISSIONS]: getCurrentUserPermissions,
    [ActionTypes.GET_USER_PERMISSIONS_SUCCEEDED]: getCurrentUserPermissionsSucceeded,
    [ActionTypes.GET_USER_PERMISSIONS_FAILED]: getCurrentUserPermissionsFailed,
  }
}

export default reducer
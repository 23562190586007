import {useEffect, useState} from "react";
import _ from "lodash";

export const useDebouncedCallback = (callback, initialValue) => {
    const [value, setValue] = useState(initialValue)
    const [changedByUser, setChangedByUser] = useState(false)
    const handleChange = (event) => {
        setChangedByUser(true)
        const newValue = event.target.value
        setValue(newValue)
    }
    const debouncedOnChange = _.debounce(handleChange, 500)
    useEffect(() => {
        if (changedByUser && callback) {
            callback(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])
    return {
        value,
        debouncedOnChange
    }
}
